import React from 'react';
import { observer } from 'mobx-react';
import Container from '../common/Container';
import { request, requestRelatorio, requestAll, requestRelatorioXLSX } from '../common/request';
import { Paper, RaisedButton, Divider, Checkbox, LinearProgress, TextField } from 'material-ui';
import { AutoComplete } from '../common/MaterialForm';
import {
  NButton, NForm, NFormTitle,
  NButtonGroup, NDatePickerInput, NDatePicker,
  NContainer, alertBox
} from '../common/NForm';
import update from 'immutability-helper';
import moment from 'moment';
import $ from 'jquery';
import MDSpinner from 'react-md-spinner';
import styles from './menuRelatorio.scss';
import { hasRole } from '../helper/SecurityHelper';
import { logAcesso } from '../helper/HistoricoHelper';
import Utils from '../utils/Utils';
import ModalApontamentos from './ModalRelatorioApontamentos';
import {
  respostaEquipamentos,
  respostaUnidadeIndustrial,
  respostaProdutos,
  respostaTurnos
} from '../common/mappers';
import ModalAuditTrail from '../forms/ModalAuditTrail';
import { withTranslation } from 'react-i18next';
import ModalRelatorioOee from './ModalRelatorioOee';
import ModalRelatorioOeePorOrdem from './ModalRelatorioOeePorOrdem';
import ModalRelatorioGaps from './ModalRelatorioGaps/ModalRelatorioGaps';
import ModalRelatorioAnomalias from './ModalRelatorioAnomalias/ModalRelatorioAnomalias';
import ModalRelatorioSensores from './ModalRelatorioSensores/ModalRelatorioSensores';
import ModalRelatorioPareto from './ModalRelatorioPareto';
import MaskFieldTime from '../utils/MaskFieldTime';
import { default as MultiSelect } from '../components/AutoComplete';
import { Mixpanel } from 'utils/Mixpanel';

const { MaskHoraMinutoSegundo } = MaskFieldTime;

function isCheckedBit(data, bit) {
  return !!(data & 1 << bit);
}

function flipBit(data, bit) {
  return data ^ (1 << bit);
}

@observer
class MenuRelatorio extends Container {
  constructor(props) {
    super(props);
    this.state = {
      loadingPage: true,
      modal: false,
      opcoesUnidadeIndustrial: [],
      opcoesEquipamentos: [],
      opcoesEtapas: [],
      lotesUnidade: [],
      grupoEquipamentos: [],
      eventos: [],
      eventosSelected: [],
      turnos: [],
      equipamentos: [],
      tipoModal: '',
      normaisInconsistentes: true,
      rejeitados: false,
      ordensPredecessoras: true,
      unidadeText: '',
      unidadeIndustrialSelected: '',
      equipamentoSelected: '',
      equipamentoText: '',
      loteSelected: '',
      loteText: '',
      etapaSelected: '',
      agrupamentoReport: 'diario',
      etapaText: '',
      periodoReport: 7,
      dataInicioReport: '',
      dataFimReport: '',
      infoRampUpChecked: 0,
      rampUpHour: localStorage.getItem('RampUP_HOUR'),
      rampUpPercent: localStorage.getItem('RampUP_PERCENT'),
      infoSmartSetupChecked: 0,
      permissoes: {
        apontamento: hasRole(['ROLE_RELATORIO_APONTAMENTOS_VISUALIZAR', 'ROLE_RELATORIO_APONTAMENTOS_GERAR_RELATORIO']),
        desvioRota: hasRole(['ROLE_RELATORIO_DESVIO_ROTA_VISUALIZAR', 'ROLE_RELATORIO_DESVIO_ROTA_GERAR_RELATORIO']),
        oee: hasRole(['ROLE_RELATORIO_OEE_VISUALIZAR', 'ROLE_RELATORIO_OEE_GERAR_RELATORIO']),
        leadTime: hasRole(['ROLE_RELATORIO_LEAD_TIME_VISUALIZAR', 'ROLE_RELATORIO_LEAD_TIME_GERAR_RELATORIO']),
        loteInconsistente: hasRole(['ROLE_RELATORIO_LOTES_INCONSISTENTE_VISUALIZAR', 'ROLE_RELATORIO_LOTES_INCONSISTENTE_GERAR_RELATORIO']),
        gargalo: hasRole(['ROLE_RELATORIO_GARGALO_VISUALIZAR', 'ROLE_RELATORIO_GARGALO_GERAR_RELATORIO']),
        holdingTime: hasRole(['ROLE_RELATORIO_DESVIO_HOLDING_TIME_VISUALIZAR', 'ROLE_RELATORIO_DESVIO_HOLDING_TIME_GERAR_RELATORIO']),
        cadastro: hasRole(['ROLE_RELATORIO_CADASTRO_PRODUTO_VISUALIZAR', 'ROLE_RELATORIO_CADASTRO_PRODUTO_GERAR_RELATORIO']),
        setup: hasRole(['ROLE_RELATORIO_SETUP_VISUALIZAR', 'ROLE_RELATORIO_SETUP_GERAR_RELATORIO']),
        rampUp: hasRole(['ROLE_RELATORIO_SETUP_RAMPUP_VISUALIZAR']),
        auditTrail: hasRole(['ROLE_RELATORIO_AUDIT_TRAIL_VISUALIZAR', 'ROLE_RELATORIO_AUDIT_TRAIL_GERAR_RELATORIO']),
        oeePorOdem: hasRole(['ROLE_RELATORIO_OEE_VISUALIZAR', 'ROLE_RELATORIO_OEE_GERAR_RELATORIO']),
        fluxo: hasRole(['ROLE_RELATORIO_FLUXO_VISUALIZAR', 'ROLE_RELATORIO_FLUXO_GERAR_RELATORIO']),
        cadastroUsuario: hasRole(['ROLE_RELATORIO_CADASTRO_USUARIO_VISUALIZAR']),
        cadastroEquipamento: hasRole(['ROLE_RELATORIO_CADASTRO_EQUIPAMENTOS_VISUALIZAR']),
        cadastroLote: hasRole(['ROLE_RELATORIO_CADASTRO_LOTE_VISUALIZAR']),
        gaps: hasRole(['ROLE_RELATORIO_APONTAMENTOS_VISUALIZAR', 'ROLE_RELATORIO_APONTAMENTOS_GERAR_RELATORIO']),
        anomalies: hasRole(['ROLE_RELATORIO_APONTAMENTOS_VISUALIZAR', 'ROLE_RELATORIO_APONTAMENTOS_GERAR_RELATORIO']),
        sensors: hasRole(['ROLE_RELATORIO_APONTAMENTOS_VISUALIZAR', 'ROLE_RELATORIO_APONTAMENTOS_GERAR_RELATORIO']),
        pareto: hasRole(['ROLE_RELATORIO_PARETO_VISUALIZAR', 'ROLE_RELATORIO_PARETO_GERAR_RELATORIO'])
      },
      dataUnidade: '',
      mesesUnidade: [],
      setupReport: true,
      erroUnidade: '',
      equipamentoReport: [],
      equipmentReport: '',
      erroEquipamento: false,
      erroMSG: '',
      erro_inicio: false,
      erro_fim: false,
      erro_equipamento: '',
      erro_modo: '',
      dataInicio: '',
      dataFim: '',
      turnoPersonalizado: [],
      erroDataInicioReport: false,
      erroDataFimReport: false,
      grupoEquipamentos: [],
      eventos: [],
      eventosSelected: [],
      textProductReport: '',
      erro_equipamentoReport: '',
      processo: false,
      encerrado: false,
      errors: {},
      batchBit: 1,
      analitico: false,
      unidadeIndustrialChecked: false,
      equipamentoChecked: false,
      loteChecked: false,
      buttonGerarRelatorioApontamentoDisabled: false,
      searchTextFuncionalidade: '',
      funcionalidadeSelecionada: '',
      produtos: [],
      produto: undefined,
      desconsiderarCheckinCheckout: false,
      relatorioCadastroLote: undefined,
      produtoFiltro: {},
      checkedUnidadeIndustrialInput: true,
      checkedProdutoInput: false,
      checkedEmProcessamento: true,
      checkedEncerradas: true,
      checkedDescartadas: true,
      ehGrupoEquipamento: false
    };

    this.contentModal = this.contentModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.formularioDesvioRota = this.formularioDesvioRota.bind(this);
    this.gerarRelatorioDesvioRota = this.gerarRelatorioDesvioRota.bind(this);
    this.formularioSetup = this.formularioSetup.bind(this);
    this.gerarRelatorioSetup = this.gerarRelatorioSetup.bind(this);
    this.formularioLeadTime = this.formularioLeadTime.bind(this);
    this.gerarRelatorioLeadTime = this.gerarRelatorioLeadTime.bind(this);
    this.formularioGargalo = this.formularioGargalo.bind(this);
    this.formularioHoldingTime = this.formularioHoldingTime.bind(this);
    this.gerarRelatorioHoldingTime = this.gerarRelatorioHoldingTime.bind(this);
    this.radioButton = this.radioButton.bind(this);
    this.limpaUnidadeIndustrial = this.limpaUnidadeIndustrial.bind(this);
    this.setEquipamento = this.setEquipamento.bind(this);
    this.setErrorMessage = this.setErrorMessage.bind(this);
    this.formularioRelatorioCadastro = this.formularioRelatorioCadastro.bind(this);
    this.gerarRelatorioCadastro = this.gerarRelatorioCadastro.bind(this);
    this.gerarRelatorioFluxo = this.gerarRelatorioFluxo.bind(this);
    this.alternarAtualizacaoAutomatica = this.alternarAtualizacaoAutomatica.bind(this);
    this.handleEventos = this.handleEventos.bind(this);
  }

  componentDidMount() {
    this.carregarTodosEquipamentos();
    this.carregarEventosSetup();

    requestAll([
      {
        url: this.endpoints.EQUIPAMENTOS,
        modelo: 'equipments',
        modificador: respostaEquipamentos,
        acao: true
      }, {
        url: this.endpoints.EQUIPAMENTOS,
        modelo: 'equipamentos',
        destino: 'state',
        modificador: respostaEquipamentos,
        acao: true
      }, {
        url: this.endpoints.UNIDADE_INDUSTRIAL,
        modelo: 'sessions',
        modificador: respostaUnidadeIndustrial,
        acao: true
      }, {
        url: this.endpoints.TURNO,
        modelo: 'turnos',
        destino: 'state',
        modificador: respostaTurnos,
        acao: true
      }, {
        url: this.endpoints.PRODUTO + '/lote',
        modelo: 'produtos',
        destino: 'state',
        modificador: respostaProdutos,
        acao: true
      }, {
        url: this.endpoints.GRUPO_EQUIPAMENTO + '?comEquipamento=true',
        modelo: 'grupoEquipamentos',
        destino: 'state',
        acao: true
      }
    ], this);
  }

  openModal(tipo, sigla) {
    // Registra o log de acesso do relatório ao abrir o 
    // modal conforme a sigla do módulo SGCA informada.
    if (sigla && sigla != 'RELATORIO_CADASTRO') {
      logAcesso(sigla);
    }

    if (tipo === 'apontamento') {
      // Define um valor padrão para o período customizado
      this.setState((props, state) => ({
        periodoReport: 'Hoje'
      }));
    }

    this.setState({
      modal: true,
      tipoModal: tipo
    });
  }

  closeModal() {
    this.setState({
      modal: false,
      tipoModal: '',
      normaisInconsistentes: true,
      rejeitados: false,
      unidadeText: '',
      unidadeIndustrialSelected: '',
      equipamentoSelected: '',
      eventosSelected: [],
      equipamentoText: '',
      loteSelected: '',
      loteText: this.utils.getReferenciaLoteOrdem().toUpperCase(),
      etapaSelected: '',
      agrupamentoReport: 'diario',
      etapaText: '',
      periodoReport: 7,
      dataInicioReport: '',
      dataFimReport: '',
      dataUnidade: '',
      mesesUnidade: [],
      setupReport: true,
      erroUnidade: '',
      equipamentoReport: [],
      equipmentReport: '',
      erroEquipamento: false,
      erroMSG: '',
      erro_inicio: false,
      erro_fim: false,
      erro_equipamento: '',
      erro_modo: '',
      dataInicio: '',
      dataFim: '',
      turnoPersonalizado: [],
      erroDataInicioReport: false,
      erroDataFimReport: false,
      erro_equipamentoReport: '',
      textProductReport: '',
      processo: false,
      encerrado: false,
      errors: {},
      batchBit: 1,
      erroDataInicial: false,
      erroDataFinal: false,
      analitico: false,
      erroInformacoes: false,
      erroSessaoUm: false,
      unidadeIndustrialChecked: false,
      equipamentoChecked: false,
      loteChecked: false,
      produto: undefined,
      funcionalidadeSelecionada: '',
      searchTextFuncionalidade: '',
      checkedEmProcessamento: true,
      checkedEncerradas: true,
      checkedDescartadas: true,
      ehGrupoEquipamento: false,
      infoRampUpChecked: 0,
      errorRampUpHour: false,
      errorRampUpPercent: false,
      rampUpHour: localStorage.getItem("RampUP_HOUR"),
      rampUpPercent: localStorage.getItem("RampUP_PERCENT"),
      infoSmartSetupChecked: 0
    }, () => { this.carregarTodosEquipamentos(); });
  }

  _handleCheck(bit) {
    this.setState(update(this.state, {
      batchBit: { $set: flipBit(this.state.batchBit, bit) },
      errors: {
        selecionar_tipo_lote: { $set: undefined }
      }
    }));
  }

  checkEquipamento(equipChecked) {
    this.setState({ equipamentoChecked: equipChecked, erroSessaoUm: false });

    if (!equipChecked) {
      this.setState({ equipamentoText: '', equipamentoSelected: '' });
    }
  }

  carregarTodosEquipamentos() {
    request(this.endpoints.EQUIPAMENTOS, { method: 'get' })
      .then(response => {
        if (response.status === 200) { return response.json(); }
      })
      .then(equips => {
        equips = equips.map(e => {
          return { text: `${e.tag} - ${e.nome}`, value: e.id };
        });

        this.setState({
          opcoesEquipamentos: equips
        });
      });
  }

  carregarEventosSetup() {
    request(this.endpoints.EVENTO, { method: 'get' })
      .then(response => {
        if (response.status === 200) { return response.json(); }
      })
      .then(eventos => {
        eventos = eventos
          .filter(e => e.classe === 13)
          .map(e => {
            let texto = `${e.codigo} - ${e.nome}`;

            return { text: texto, value: e.id };
          })
          .sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));

        this.setState({
          eventos: eventos
        });
      });
  }

  limpaUnidadeIndustrial() {
    this.setState({
      unidadeText: '',
      unidadeIndustrialSelected: '',
      equipamentoText: '',
      lote: '',
      nomeProduto: '',
    });
  }

  handleUnidadeIndustrialCheck(value) {
    this.setState({ unidadeIndustrialChecked: value, erroSessaoUm: false });

    if (!value) {
      this.limpaUnidadeIndustrial();
    }
  }

  setUnidadeIndustrialComEtapa(nomeUnidade, allUnd) {
    let obj = allUnd.find(i => i.text == nomeUnidade);
    var etapas = [];

    if (obj) {
      let unidadeIndustrialSelected = this.store.sessions.find(s => s.id == obj.value);
      let etapasUnidadeIndustrial = unidadeIndustrialSelected.stages.filter(s => !s.length);

      for (let i = 0; i < etapasUnidadeIndustrial.length; i++) {
        etapas.push({ text: etapasUnidadeIndustrial[i].name, value: etapasUnidadeIndustrial[i].id });
      }

      this.setState({
        unidadeIndustrialSelected: obj,
        opcoesEtapas: etapas,
        unidadeText: obj.text,
        opcoesEquipamentos: [],
        equipamentoSelected: '',
      });
    }
  }

  setUnidadeIndustrialSemResetarEquipamento(nomeUnidade, allUnd, resetarOpcoesEquipamento) {
    let obj = allUnd.find(i => i.text == nomeUnidade);
    var etapas = [];

    if (obj) {
      let unidadeIndustrialSelected = this.store.sessions.find(s => s.id == obj.value);
      let etapasUnidadeIndustrial = unidadeIndustrialSelected.stages.filter(s => !s.length);
      let equipamentos = [];

      for (let i = 0; i < etapasUnidadeIndustrial.length; i++) {
        etapas.push({ text: etapasUnidadeIndustrial[i].name, value: etapasUnidadeIndustrial[i].id });
        if (etapasUnidadeIndustrial[i].ComEquipamento) {
          var equipamentosEtapa = etapasUnidadeIndustrial[i].equipments;

          for (let j = 0; j < equipamentosEtapa.length; j++) {
            equipamentos.push({ text: `${equipamentosEtapa[j].tag} - ${equipamentosEtapa[j].name}`, value: equipamentosEtapa[j].id });
          }
        }
      }

      this.setState({
        unidadeIndustrialSelected: obj,
        opcoesEtapas: etapas,
        unidadeText: obj.text,
        opcoesEquipamentos: equipamentos
      });
    }
  }

  setEquipamento(nomeEquipamento, allEqp) {
    let obj = allEqp.find(i => i.text === nomeEquipamento);
    var grupo = this.state.grupoEquipamentos.filter(e => e.nome == nomeEquipamento)[0];

    if (obj === undefined) {
      this.setState({
        equipamentoSelected: '',
        equipamentoText: '',
        infoRampUpChecked: false
      });

      return;
    }

    this.setState({
      equipamentoSelected: obj,
      equipamentoText: obj.text,
      ehGrupoEquipamento: grupo != undefined
    });
  }

  handleEventos(options) {
    this.setState({ eventosSelected: options });
  }

  setLote(e) {
    this.setState((state, props) => {
      return { buttonGerarRelatorioApontamentoDisabled: true };
    });

    let lote_numero = e.target.value.trim();
    let incluirLotesFinalizados = true;

    request(this.endpoints.LOTE_NUMERO + `/${lote_numero}?finalizado=${incluirLotesFinalizados}`).then(response => {
      if (response.status === 200) {
        return response.json();
      }
    }).then(data => {
      if (!data) {
        this.setState({
          loteSelected: '',
          loteText: '',
          buttonGerarRelatorioApontamentoDisabled: false,
        });

        return;
      }

      let lote = { numero: data.numero, id: data.id, nomeProduto: data.produto.nome };

      if (lote !== undefined) {
        this.setState({
          loteSelected: lote,
          loteText: lote.nomeProduto
        });
      } else {
        this.setState({
          loteSelected: '',
          loteText: this.utils.getReferenciaLoteOrdem().toUpperCase()
        });
      }
      this.setState((state, props) => {
        return { buttonGerarRelatorioApontamentoDisabled: false };
      });
    });
  }

  setEtapaComEquipamento(nomeEtapa, allEtp) {
    let obj = allEtp.find(i => i.text == nomeEtapa);

    if (obj) {
      let unidadeIndustrialSelected = this.store.sessions.find(s => s.id == this.state.unidadeIndustrialSelected.value);
      let etapaSelected = unidadeIndustrialSelected.stages.filter(u => u.id == obj.value);
      let equipamentosEtapa = etapaSelected[0].equipments.filter(e => !e.length);
      var equipamentos = [];

      for (let i = 0; i < equipamentosEtapa.length; i++) {
        equipamentos.push({ text: equipamentosEtapa[i].name, value: equipamentosEtapa[i].id });
      }

      this.setState({
        etapa: obj,
        etapaText: obj.text,
        searchTextEquipmento: '',
        opcoesEquipamentos: equipamentos
      });
    }
  }

  radioButton(event) {
    var valor = $(event.target).data('value');

    if (valor === undefined) {
      valor = event.target.parentElement.
        querySelector('div[class="control_indicator"]').dataset.value;
    }
    console.log(valor);
    this.setState({
      periodoReport: valor
    });
  }

  checkProcesso() {
    var ischecked = $('#processo').is(':checked');

    ischecked ?
      this.setState({
        processo: true
      })
      :
      this.setState({
        processo: false
      });
  }

  checkEncerrado() {
    var ischecked = $('#encerrado').is(':checked');

    ischecked ?
      this.setState({
        encerrado: true
      })
      :
      this.setState({
        encerrado: false,
        data_inicio: '',
        data_fim: ''
      });
  }

  gerarRelatorioDesvioRota() {
    var { dataInicioReport, dataFimReport, unidadeIndustrialSelected, etapa, equipamentoSelected } = this.state;
    var error = false;

    if (dataInicioReport) {
      dataInicioReport = dataInicioReport.format('DD-MM-YYYY');
    }

    if (dataFimReport) {
      dataFimReport = dataFimReport.format('DD-MM-YYYY');
    }

    if (!unidadeIndustrialSelected) {
      this.setState({
        erroUnidadeIndustrial: true
      });
      error = true;
    } else {
      this.setState({
        erroUnidadeIndustrial: false
      });
    }

    if (!dataInicioReport) {
      this.setState({
        erroDataInicial: true
      });
      error = true;
    } else {
      this.setState({
        erroDataInicial: false
      });
    }

    if (!dataFimReport) {
      this.setState({
        erroDataFinal: true
      });
      error = true;
    } else {
      this.setState({
        erroDataInicial: false
      });
    }

    let url = `${this.endpoints.RELATORIO_DESVIO_ROTA}`;

    if (unidadeIndustrialSelected) { url += '?unidadeIndustrial=' + unidadeIndustrialSelected.value; } //Somente unidade selecionada

    if (unidadeIndustrialSelected && etapa) { url += '&etapa=' + etapa.value; }

    if (unidadeIndustrialSelected && equipamentoSelected) { url += '&equipamento=' + equipamentoSelected.value; } // und + etapa + equipamento + periodo

    if (!unidadeIndustrialSelected) {
      if (dataInicioReport != null && dataFimReport != null) { url += '?dataInicio=' + dataInicioReport + '&dataFim=' + dataFimReport; } //Somente periodo
    }
    else if (unidadeIndustrialSelected) {
      if (dataInicioReport != null && dataFimReport != null) { url += '&dataInicio=' + dataInicioReport + '&dataFim=' + dataFimReport; }
    }

    if (error != true) {
      this.openModal('loading_relatorio');
      requestRelatorio(url, this.props.t("titles.routeDeviationReport"))
        .then(() => {
          this.closeModal();
        })
        .catch(() => {
          this.setState({
            modal: true,
            tipoModal: 'erro',
            erroMSG: this.props.t("errors.cannotGenerateReport")
          });
        });
    }
  }

  ampersand(url) {
    return (url[url.length - 1] === '?') ? '' : '&';
  }

  gerarRelatorioLeadTime() {
    var { unidadeIndustrialSelected, analitico, periodoReport } = this.state;
    var error = false;
    var url = '', start_date = '', end_date = '';

    if (periodoReport === -1) {
      start_date = $('#start_date').val();
      end_date = $('#end_date').val();

      if (start_date) {
        start_date = moment(start_date, 'DD/MM/YYYY').format('DD-MM-YYYY');
      }

      if (end_date) {
        end_date = moment(end_date, 'DD/MM/YYYY').format('DD-MM-YYYY');
      }

      if (start_date == '' || end_date == '') {
        this.setState({ erroDatas: true });
        error = true;
      } else {
        this.setState({ erroDatas: false });
        error = false;
      }

      if (start_date != '' && end_date == '') {
        this.setState({ erroDataFinal: true });
        error = true;
      } else {
        this.setState({ erroDataFinal: false });
        error = false;
      }

      if (start_date == '' && end_date != '') {
        this.setState({ erroDataInicial: true });
        error = true;
      } else {
        this.setState({ erroDataInicial: false });
        error = false;
      }
    } else {
      start_date = moment().subtract(periodoReport, 'days').format('DD-MM-YYYY');
      end_date = moment().format('DD-MM-YYYY');
    }

    if (!unidadeIndustrialSelected) {
      this.setState({ erroUnidadeIndustrial: true });
      error = true;
    } else {
      this.setState({ erroUnidadeIndustrial: false });
      error = false;
    }


    if (unidadeIndustrialSelected) {
      url += 'lead-time' + '/' + unidadeIndustrialSelected.value;
      if (periodoReport == -1) {
        if (start_date != '' && end_date != '') {
          url += '/' + start_date + '/' + end_date;
        } else {
          return false;
        }
      } else {
        url += '/' + start_date + '/' + end_date;
      }
      url += '/' + analitico;

      this.openModal('loading_relatorio');
      requestRelatorio(`${this.endpoints.RELATORIO}/${url}`, this.props.t("titles.leadTimeReport"))
        .then(() => {
          this.closeModal();
        })
        .catch(() => {
          this.setState({
            modal: true,
            tipoModal: 'erro',
            erroMSG: this.props.t("errors.cannotGenerateReport")
          });
        });
    }
  }

  gerarRelatorioLotesInconsistentes() {
    var { unidadeIndustrialSelected, processo, encerrado, dataInicioReport, dataFimReport, equipamentoSelected, ehGrupoEquipamento } = this.state;
    var error = false;

    if (!unidadeIndustrialSelected) return alertBox(this.props.t("errors.selectIndustrialUnit"), this.props.t("labels.warning", { ns: 'common' }), 'warning');
    if (encerrado == true) {
      if (dataInicioReport) {
        dataInicioReport = dataInicioReport.format('DD-MM-YYYY');
      }
      if (dataFimReport) {
        dataFimReport = dataFimReport.format('DD-MM-YYYY');
      }

      if (!dataInicioReport) {
        this.setState({ erroDataInicial: true });
        error = true;
      } else {
        this.setState({ erroDataInicial: false });
        error = false;
      }

      if (!dataFimReport) {
        this.setState({ erroDataFinal: true });
        error = true;
      } else {
        this.setState({ erroDataFinal: false });
        error = false;
      }
    }

    if (error == false) {
      let url = `${this.endpoints.RELATORIO_LOTES_INCONSISTENTES}?`;

      if (unidadeIndustrialSelected != '') { url += 'unidadeIndustrial=' + unidadeIndustrialSelected.value; };

      if (unidadeIndustrialSelected && equipamentoSelected) { url += '&equipamentoId=' + equipamentoSelected.value + '&ehGrupo=' + ehGrupoEquipamento };

      if (unidadeIndustrialSelected == '' && equipamentoSelected) { url += 'equipamentoId=' + equipamentoSelected.value + '&ehGrupo=' + ehGrupoEquipamento };

      if (unidadeIndustrialSelected == '' && processo == true) { url += 'emProcessamento=' + processo; };

      if (unidadeIndustrialSelected != '' && processo == true) { url += '&emProcessamento=' + processo; };

      if ((unidadeIndustrialSelected == '' && processo == false) && encerrado == true) { url += 'finalizado=' + encerrado + '&dataInicio=' + dataInicioReport + '&dataFim=' + dataFimReport; };

      if ((unidadeIndustrialSelected != '' || processo == true) && encerrado == true) { url += '&finalizado=' + encerrado + '&dataInicio=' + dataInicioReport + '&dataFim=' + dataFimReport; };

      this.openModal('loading_relatorio');
      requestRelatorio(url, this.props.t("titles.inconsistentBatchReport", { primaryReference: this.utils.getReferenciaLoteOrdem() })).then(() => {
        this.closeModal();
      })
        .catch(() => {
          this.setState({
            modal: true,
            tipoModal: 'erro',
            erroMSG: this.props.t("errors.cannotGenerateReport")
          });
        });
    }
  }

  gerarRelatorioGargalo() {
    var { unidadeIndustrialSelected } = this.state;
    var error = false;
    var url = '' //, start_date = $('#start_date').val(), end_date = $('#end_date').val();
    let { start_date, end_date } = this.state;

    if (start_date) {
      start_date = moment(start_date).format('DD-MM-YYYY');
    }

    if (end_date) {
      end_date = moment(end_date).format('DD-MM-YYYY');
    }

    if (start_date == '' || end_date == '') {
      this.setState({ erroDatas: true });
      error = true;
    } else {
      this.setState({ erroDatas: false });
      error = false;
    }

    if (start_date != '' && end_date == '') {
      this.setState({ erroDataFinal: true });
      error = true;
    } else {
      this.setState({ erroDataFinal: false });
      error = false;
    }

    if (start_date == '' && end_date != '') {
      this.setState({ erroDataInicial: true });
      error = true;
    } else {
      this.setState({ erroDataInicial: false });
      error = false;
    }

    if (unidadeIndustrialSelected == undefined || unidadeIndustrialSelected == '') {
      this.setState({ erroUnidadeIndustrial: true });
      error = true;
    } else if (!error) {
      this.setState({ erroUnidadeIndustrial: false });
      error = false;
    }


    if (unidadeIndustrialSelected && start_date && end_date) {
      this.openModal('loading_relatorio');
      url += this.endpoints.RELATORIO_GARGALO + '/' + unidadeIndustrialSelected.value + '/' + start_date + '/' + end_date + '/' + this.state.desconsiderarCheckinCheckout;
      requestRelatorio(url, this.props.t("titles.bottleneckReport"))
        .then(() => {
          this.closeModal();
        })
        .catch(() => {
          this.setState({
            modal: true,
            tipoModal: 'erro',
            erroMSG: this.props.t("errors.cannotGenerateReport")
          });
        });
    }
  }

  gerarRelatorioHoldingTime() {

    var { unidadeIndustrialSelected, dataInicioReport, dataFimReport, batchBit } = this.state;

    if ((!unidadeIndustrialSelected) || (!unidadeIndustrialSelected.value)) {
      this.setState({
        errors: {
          selecionar_unidade_industrial: this.props.t("errors.requiredField", { ns: 'validation' }),
        }
      });
      return;
    }

    if (dataInicioReport) {
      dataInicioReport = dataInicioReport.format('DD-MM-YYYY') + ' 00:00:00';
    }

    if (dataFimReport) {
      dataFimReport = dataFimReport.format('DD-MM-YYYY') + ' 23:59:59';
    }

    if (isCheckedBit(batchBit, 1) && isCheckedBit(batchBit, 2)) {

      if (dataInicioReport == null || dataFimReport == null) {
        const newState = {};

        if (dataInicioReport == null) {
          newState.data_inicio_required = { $set: this.props.t("errors.requiredField", { ns: 'validation' }) };
        }

        if (dataFimReport == null) {
          newState.data_fim_required = { $set: this.props.t("errors.requiredField", { ns: 'validation' }) };
        }

        this.setState(update(this.state, {
          errors: newState
        }));

      } else {

        const url = `${this.endpoints.RELATORIO_DESVIO_HOLDING_TIME}/${unidadeIndustrialSelected.value}/${dataInicioReport}/${dataFimReport}`;

        this.openModal('loading_relatorio');
        requestRelatorio(url, this.props.t("titles.holdingTimeDeviationReport")).then(() => {
          this.closeModal();
        })
          .catch(() => {
            this.setState({
              modal: true,
              unidadeIndustrialSelected: '',
              textUnidade: '',
              tipoModal: 'erro',
              erroMSG: this.props.t("errors.cannotGenerateReport")
            });
          });

        this.setState({
          errors: {}
        });
      }

    } else if (isCheckedBit(batchBit, 1)) {
      this.openModal('loading_relatorio');
      const url = `${this.endpoints.RELATORIO_DESVIO_HOLDING_TIME}/${unidadeIndustrialSelected.value}/false`;
      requestRelatorio(url, this.props.t("titles.holdingTimeDeviationReport")).then(() => {
        this.closeModal();
      })
        .catch(() => {
          this.setState({
            modal: true,
            modalType: 'erro',
            unidadeIndustrialSelected: '',
            textUnidade: '',
            erroMSG: this.props.t("errors.cannotGenerateReport")
          });
        });

    } else if (isCheckedBit(batchBit, 2)) {

      if (dataInicioReport == null || dataFimReport == null) {

        const newState = {};

        if (dataInicioReport == null) {
          newState.data_inicio_required = { $set: this.props.t("errors.requiredField", { ns: 'validation' }) };
        }

        if (dataFimReport == null) {
          newState.data_fim_required = { $set: this.props.t("errors.requiredField", { ns: 'validation' }) };
        }

        this.setState(update(this.state, {
          errors: newState
        }));
      } else {
        this.openModal('loading_relatorio');
        const url = `${this.endpoints.RELATORIO_DESVIO_HOLDING_TIME}/${unidadeIndustrialSelected.value}/true/${dataInicioReport}/${dataFimReport}`;
        requestRelatorio(url, this.props.t("titles.holdingTimeDeviationReport")).then(() => {
          this.closeModal();
        })
          .catch(() => {
            this.setState({
              modal: true,
              tipoModal: 'erro',
              unidadeIndustrialSelected: '',
              textUnidade: '',
              erroMSG: this.props.t("errors.cannotGenerateReport")
            });
          });

        this.setState({
          errors: {}
        });
      }
    } else {
      this.setState({
        errors: {
          selecionar_tipo_lote: this.props.t("errors.requiredField", { ns: 'validation' }),
        }
      });
    }
  }

  gerarRelatorioSetup() {
    var {
      dataInicioReport,
      dataFimReport,
      unidadeIndustrialSelected,
      equipamentoSelected,
      ehGrupoEquipamento,
      infoRampUpChecked,
      periodoReport,
      rampUpHour,
      rampUpPercent,
      infoSmartSetupChecked,
      eventosSelected } = this.state;

    var error = false;

    if (dataInicioReport) {
      dataInicioReport = dataInicioReport.format('DD-MM-YYYY');
    }

    if (dataFimReport) {
      dataFimReport = dataFimReport.format('DD-MM-YYYY');
    }

    if (!unidadeIndustrialSelected) {
      this.setState({
        erroUnidadeIndustrial: true
      });
      error = true;
    } else {
      this.setState({
        erroUnidadeIndustrial: false
      });
    }

    if (!dataInicioReport) {
      this.setState({
        erroDataInicial: true
      });
      error = true;
    } else {
      this.setState({
        erroDataInicial: false
      });
    }

    if (!dataFimReport) {
      this.setState({
        erroDataFinal: true
      });
      error = true;
    } else {
      this.setState({
        erroDataFinal: false
      });
    }

    if (infoRampUpChecked) {
      if (periodoReport == 1) {
        if (!rampUpHour) {
          this.setState({
            errorRampUpHour: true
          });
          error = true;
        }
        else {
          this.setState({
            errorRampUpHour: false
          });
        }
      }
      else {
        if (!rampUpPercent || rampUpPercent == '%') {
          this.setState({
            errorRampUpPercent: true
          });
          error = true;
        }
        else {
          this.setState({
            errorRampUpPercent: false
          });
        }
      }
    }

    let url = `${this.endpoints.RELATORIO_SETUP}`;

    if (unidadeIndustrialSelected) { url += '?unidadeIndustrialId=' + unidadeIndustrialSelected.value + '&unidadeIndustrialNome=' + unidadeIndustrialSelected.text; } //Somente unidade selecionada

    if (unidadeIndustrialSelected && equipamentoSelected) { url += '&equipamentoId=' + equipamentoSelected.value + '&equipamentoNome=' + equipamentoSelected.text; } // und + equipamento + periodo

    url += '&ehGrupo=' + ehGrupoEquipamento;

    if (!unidadeIndustrialSelected) {
      if (dataInicioReport && dataFimReport) { url += '?dataInicio=' + dataInicioReport + '&dataFim=' + dataFimReport; } //Somente periodo
    }
    else if (unidadeIndustrialSelected) {
      if (dataInicioReport && dataFimReport) { url += '&dataInicio=' + dataInicioReport + '&dataFim=' + dataFimReport; }
    }

    url += '&extrairRampUp=' + infoRampUpChecked;

    if (infoRampUpChecked) {
      if (periodoReport == 1) {
        url += '&rampUpDuracao=' + rampUpHour;
      }
      else {
        url += '&rampUpPorcentagem=' + rampUpPercent;
      }
    }

    if (eventosSelected.length > 0) {
      eventosSelected.map(item => {
        url += '&eventos=' + item.value;
      });
    }

    url += '&guiado=' + infoSmartSetupChecked;

    if (error != true) {
      this.openModal('loading_relatorio');

      requestRelatorioXLSX(url, this.props.t("titles.setupReport"))
        .then(() => this.closeModal(),
          erro => this.erroRelatorio(erro))
        .catch(erro => this.erroRelatorio(erro));
    }
  }

  erroRelatorio(erro) {
    if (typeof erro === 'object') {
      alertBox(erro.mensagem, this.props.t("errors.cannotGenerateTitle"), 'warning');
    } else if (Util.isJson(erro) && JSON.parse(erro).status === 'erro') {
      alertBox(JSON.parse(erro).mensagem, this.props.t("errors.cannotGenerateTitle"), 'warning');
    } else {
      this.setState({
        modal: true,
        tipoModal: 'erro',
        erroMSG: this.props.t("errors.cannotGenerateReport")
      });
    }
  }

  onCheckLote(event, value) {
    if (!value) {
      this.setState({
        loteSelected: '',
        loteText: ''
      });
    }

    this.setState({
      loteChecked: value,
      ordensPredecessoras: true,
      erroSessaoUm: false
    });
  }

  formularioDesvioRota() {
    let sessionOpts = this.store.sessions.filter(s => s.stages.length);
    var opcoesUnidade = [];
    for (let i = 0; i < sessionOpts.length; i++) {
      opcoesUnidade.push({ text: sessionOpts[i].name, value: sessionOpts[i].id });
    }

    const { dataInicioReport, dataFimReport, errors } = this.state;

    return (
      <div >
        <NForm>
          <NFormTitle>{this.props.t('titles.routeDeviationReport')}</NFormTitle>
          <AutoComplete
            textFieldStyle={{ marginTop: '-16px', width: '254px' }}
            ref='unidadeIndustrial'
            underlineStyle={this.state.erroUnidadeIndustrial ? { borderColor: 'red' } : ''}
            underlineFocusStyle={{ borderColor: '#000000' }}
            fullWidth={true}
            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
            floatingLabelText={this.props.t("labels.industrialUnit")}
            title={this.props.t("labels.industrialUnit")}
            onUpdateInput={this.setUnidadeIndustrialSemResetarEquipamento.bind(this)}
            filter={AutoComplete.fuzzyFilter}
            dataSource={opcoesUnidade}
            onClick={() => this.setState({ unidadeText: '', unidadeIndustrialSelected: '', equipamentoText: '', opcoesEquipamentos: [], lote: '', nomeProduto: '' })}
            openOnFocus={true}
            id='unidadeIndustrial'
            popoverProps={{ className: 'autocomplete' }} />
          {this.state.erroUnidadeIndustrial ? <div style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '4px' }} ref="validate">{this.props.t("errors.requiredField", { ns: 'validation' })}</div> : ''}

          <br />
          <AutoComplete
            textFieldStyle={{ width: '254px' }}
            ref='etapa'
            floatingLabelText={this.props.t("labels.stage")}
            title={this.props.t("labels.stage")}
            fullWidth={true}
            onClick={() => this.setState({ etapaText: '' })}
            onUpdateInput={this.setEtapaComEquipamento.bind(this)}
            filter={AutoComplete.fuzzyFilter}
            dataSource={this.state.opcoesEtapas}
            popoverProps={{ className: 'autocomplete' }}
            openOnFocus={true}
            id='etapa' />

          <br />
          <AutoComplete
            textFieldStyle={{ width: '254px' }}
            ref='equipamento'
            floatingLabelText={this.props.t("labels.equipment")}
            title={this.props.t("labels.equipment")}
            fullWidth={true}
            onClick={() => this.setState({ equipamentoText: '' })}
            onUpdateInput={this.setEquipamento.bind(this)}
            filter={AutoComplete.fuzzyFilter}
            dataSource={this.state.opcoesEquipamentos}
            popoverProps={{ className: 'autocomplete' }}
            openOnFocus={true}
            id='equipamento' />

          <div style={{ fontFamily: 'Roboto', lineHeight: '1.15', fontSize: '14px', color: '#787878', marginTop: '30px' }}>
            {this.props.t("labels.selectPeriod")}
          </div>

          <NContainer direction="horizontal">
            <div style={{ width: '7em' }}>
              <NDatePickerInput
                className={styles.datePicker}
                require={(this.state.erroDatas || this.state.erroDataInicial)}
                selected={dataInicioReport}
                value={dataInicioReport}
                maxDate={moment()}
                id="start_date"
                label={this.props.t("labels.startDate")}
                onSelect={date => this.setState({ dataInicioReport: date, dataFimReport: null })}
                onChangeRaw={(_, date) => this.setState({ dataInicioReport: date, dataFimReport: null })}
                onChange={date => this.setState({ dataInicioReport: date, dataFimReport: null })} />
            </div>
            <div style={{ width: '7em', marginLeft: '25px' }}>
              <NDatePickerInput
                className={styles.datePicker}
                require={(this.state.erroDatas || this.state.erroDataFinal)}
                selected={dataFimReport}
                value={dataFimReport}
                minDate={dataInicioReport}
                id="end_date"
                label={this.props.t("labels.endDate")}
                maxDate={dataInicioReport != null ? moment(dataInicioReport).day(90).isAfter(moment()) ? moment() : moment(dataInicioReport).day(90) : moment()}
                onSelect={date => this.setState({ dataFimReport: date })}
                onChangeRaw={(_, date) => this.setState({ dataFimReport: date })}
                onChange={date => this.setState({ dataFimReport: date })} />
            </div>
          </NContainer>

          <Divider />

          <br />
          <NButtonGroup>
            <RaisedButton onClick={this.gerarRelatorioDesvioRota} backgroundColor='#2D4F7F' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100 }} labelColor='#FFF' label={this.props.t("labels.generate", { ns: 'common' })} />
            <RaisedButton onClick={this.closeModal} buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100, marginLeft: '.5em' }} label={this.props.t("labels.cancel", { ns: 'common' })} />
          </NButtonGroup>
        </NForm>
      </div>
    );
  }

  formularioLeadTime() {
    let sessionOpts = this.store.sessions.filter(s => s.stages.length);
    var opcoesUnidade = [];
    for (let i = 0; i < sessionOpts.length; i++) {
      opcoesUnidade.push({ text: sessionOpts[i].name, value: sessionOpts[i].id });
    }

    return (
      <div className={styles.relatorioLeadTime}>
        <NForm>
          <NFormTitle>{this.props.t("titles.leadTimeReport")}</NFormTitle>
          <div className={'flex flex-flow-col flex-just-between'} style={{ height: '5em' }}>
            <AutoComplete
              textFieldStyle={{ marginTop: '-10px' }}
              onClick={() => this.setState({ unidadeIndustrialSelected: '', unidadeText: '' })}
              fullWidth={true}
              errorText={this.state.erro_equipamentoReport}
              underlineFocusStyle={{ borderColor: '#000000' }}
              underlineStyle={this.state.erroUnidadeIndustrial ? { borderColor: 'red' } : ''}
              floatingLabelStyle={{ color: '#5B5B5B' }}
              listStyle={{ width: '100%' }}
              floatingLabelText={this.props.t("labels.industrialUnit")}
              title={this.props.t("labels.industrialUnit")}
              onUpdateInput={this.setUnidadeIndustrialSemResetarEquipamento.bind(this)}
              filter={AutoComplete.fuzzyFilter}
              dataSource={opcoesUnidade}
              openOnFocus={true}
              popoverProps={{ className: 'autocomplete' }} />
            {this.state.erroUnidadeIndustrial ? <div style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '4px' }} ref="validate">Campo obrigatório</div> : ''}
          </div>
          <div>
            <Checkbox
              label={this.props.t("labels.analyticReport")}
              style={{ paddingBottom: '1.8em', marginTop: '1.5em' }}
              iconStyle={{ fill: '#2b4d7c', marginRight: 0 }}
              onCheck={this.state.analitico == true ? () => this.setState({ analitico: false }) : () => this.setState({ analitico: true })}
            />
          </div>
          <Divider />

          <h3 style={{ paddingTop: '2.5em', paddingBottom: '2em', fontSize: '.8em', textTransform: 'uppercase' }}> {this.props.t("labels.selectPeriod")} <span style={{ fontSize: '.7em' }} > ({this.props.t("labels.days")}) </span> </h3>
          <div className="control-group">
            <div style={{ textAlign: 'center' }}>
              <label>7</label>
              <label className="control control-radio">
                <input type="radio" checked={this.state.periodoReport == 7} id="radioSete" name="periodo" />
                <div data-value='7' onClick={this.radioButton} className="control_indicator"></div>
              </label>
            </div>
            <Divider style={{ width: '4em', marginTop: '4px', height: '2px', backgroundColor: '#5B5B5B' }} />
            <div style={{ textAlign: 'center' }}>
              <label>15</label>
              <label className="control control-radio">
                <input type="radio" checked={this.state.periodoReport == 15} name="periodo" />
                <div data-value='15' onClick={this.radioButton} className="control_indicator"></div>
              </label>
            </div>
            <Divider style={{ width: '4em', marginTop: '4px', height: '2px', backgroundColor: '#5B5B5B' }} />
            <div style={{ textAlign: 'center' }}>
              <label>30</label>
              <label className="control control-radio">
                <input type="radio" checked={this.state.periodoReport == 30} name="periodo" />
                <div data-value='30' onClick={this.radioButton} className="control_indicator"></div>
              </label>
            </div>
            <Divider style={{ width: '4em', marginTop: '4px', height: '2px', backgroundColor: '#5B5B5B' }} />
            <div style={{ textAlign: 'center' }}>
              <label>90</label>
              <label className="control control-radio">
                <input type="radio" checked={this.state.periodoReport == 90} name="periodo" />
                <div data-value='90' onClick={this.radioButton} className="control_indicator"></div>
              </label>
            </div>
            <Divider style={{ width: '4em', marginTop: '4px', height: '2px', backgroundColor: '#5B5B5B' }} />
            <div style={{ textAlign: 'center' }}>
              <label style={{ marginLeft: '-2em' }}>{this.props.t("labels.customize", { ns: 'common' })}</label>
              <label className="control control-radio">
                <input type="radio" checked={this.state.periodoReport == -1} name="periodo" />
                <div data-value='-1' onClick={this.radioButton} className="control_indicator"></div>
              </label>
            </div>
          </div>

          {
            this.state.periodoReport == '-1' ?
              <div className={'flex'} style={{ marginBottom: '1em' }}>
                <NDatePickerInput
                  className={styles.datePicker}
                  require={(this.state.erroDatas || this.state.erroDataInicial)}
                  selected={this.state.dataInicioReport}
                  value={this.state.dataInicioReport}
                  maxDate={moment()} id="start_date"
                  label={this.props.t("labels.startDate")}
                  onChange={date => this.setState({ dataInicioReport: date, dataFimReport: null })}
                  onChangeRaw={(_, date) => this.setState({ dataInicioReport: date, dataFimReport: null })}
                  autoComplete="off" />

                <NDatePickerInput className={styles.datePicker} require={(this.state.erroDatas || this.state.erroDataFinal)} onChangeRaw={this._onDateChange}
                  selected={this.state.dataFimReport} value={this.state.dataFimReport}
                  minDate={this.state.dataInicioReport} maxDate={this.state.dataInicioReport != null ? moment(this.state.dataInicioReport).day(90).isAfter(moment()) ? moment() : moment(this.state.dataInicioReport).day(90) : moment()}
                  id="end_date" label={this.props.t("labels.endDate")} onChange={date => this.setState({ dataFimReport: date })}
                  autoComplete="off" />

              </div>
              :
              null
          }

          <div style={{ paddingTop: '1.5em' }} className={'flex flex-just-end'}>
            <RaisedButton onClick={() => this.gerarRelatorioLeadTime()} backgroundColor='#2D4F7F' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100, marginRight: '1em' }} labelColor='#FFF' label={this.props.t("labels.generate", { ns: 'common' })} />
            <RaisedButton onClick={this.closeModal}
              backgroundColor='#FFF' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100 }} label={this.props.t("labels.cancel", { ns: 'common' })} />
          </div>
        </NForm>
      </div>
    );
  }

  formularioLotesInconsistentes() {
    let sessionOpts = this.store.sessions.filter(s => s.stages.length);
    var opcoesUnidade = [];
    for (let i = 0; i < sessionOpts.length; i++) {
      opcoesUnidade.push({ text: sessionOpts[i].name, value: sessionOpts[i].id });
    }

    let sessions = JSON.parse(JSON.stringify(this.store.sessions));

    let grupo_equipamento = this.state.grupoEquipamentos.map(g => {
      return { value: g.id, text: g.nome };
    });

    let equipments = grupo_equipamento.concat([].concat.apply([],
      [].concat.apply([],
        sessions.map(s =>
          s.stages.map(ss => ss.equipments)))).reduce((prev, elem) => {
            if (!prev.map(p => p.value).includes(elem.id)) prev.push({ value: elem.id, text: elem.name });
            return prev;
          }, []).sort((a, b) => a.text < b.text ? -1 : a.text > b.text ? 1 : 0));

    const { dataInicioReport, dataFimReport } = this.state;

    return (
      <div className={styles.report}>
        <NForm>
          <NFormTitle>{this.props.t("titles.inconsistentBatchReport", { primaryReference: this.utils.getReferenciaLotesOrdens() })}</NFormTitle>

          <div className={'flex flex-flow-col flex-just-between'}>
            <AutoComplete
              textFieldStyle={{ marginTop: '-16px', width: '254px' }}
              ref='unidadeIndustrial'
              floatingLabelText={this.props.t("labels.industrialUnit")}
              title={this.props.t("labels.industrialUnit")}
              underlineFocusStyle={{ borderColor: '#000000' }}
              fullWidth={true}
              onClick={() => this.setState({ unidadeIndustrialSelected: '', unidadeText: '' })}
              floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
              onUpdateInput={this.setUnidadeIndustrialSemResetarEquipamento.bind(this)}
              filter={AutoComplete.fuzzyFilter}
              popoverProps={{ className: 'autocomplete' }}
              dataSource={opcoesUnidade}
              openOnFocus={true} />

            <AutoComplete
              textFieldStyle={{ width: '254px' }}
              ref='equipamento'
              underlineFocusStyle={{ borderColor: '#000000' }}
              floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
              floatingLabelText={this.props.t('labels.equipment')}
              title={this.props.t('labels.equipment')}
              fullWidth={true}
              onClick={() => this.setState({ equipamentoText: '', equipamentoSelected: '' })}
              onUpdateInput={this.setEquipamento.bind(this)}
              filter={AutoComplete.fuzzyFilter}
              dataSource={equipments}
              popoverProps={{ className: 'autocomplete' }}
              openOnFocus={true}
              id='equipamento'
            />
          </div>

          <br />
          <div style={{ marginTop: '-7px', marginBottom: '3px', fontFamily: 'Roboto', lineHeight: '1.15', fontSize: '14px', paddingBottom: '15px', color: '#787878', textTransform: 'uppercase' }}>
            {this.props.t("labels.batchType", { primaryReference: this.utils.getReferenciaLoteOrdem() })}
          </div>

          <Checkbox id='processo'
            checked={this.state.processo}
            onCheck={this.checkProcesso.bind(this)}
            label={this.props.t("labels.processing")} labelStyle={{ color: '#5B5B5B' }}
            style={styles.checkbox}
            iconStyle={{ fill: '#2b4d7c' }}
          />

          <Checkbox id='encerrado'
            checked={this.state.encerrado}
            onCheck={this.checkEncerrado.bind(this)}
            label={this.props.t("labels.finished")} labelStyle={{ color: '#5B5B5B' }}
            style={styles.checkbox}
            iconStyle={{ fill: '#2b4d7c' }}
          />

          {this.state.encerrado ?
            <div>
              <div style={{ fontFamily: 'Roboto', lineHeight: '1.15', fontSize: '14px', color: '#787878', marginTop: '30px', textTransform: 'uppercase' }}>
                {this.props.t("labels.selectPeriod")}
              </div>

              <NContainer direction="horizontal">
                <div style={{ width: '7em' }}>
                  <NDatePickerInput
                    className={styles.datePicker}
                    require={(this.state.erroDatas || this.state.erroDataInicial)}
                    selected={dataInicioReport}
                    value={this.state.dataInicioReport}
                    maxDate={moment()}
                    id="data_inicio"
                    label={this.props.t("labels.startDate")}
                    onChange={date => this.setState({ dataInicioReport: date, dataFimReport: null })}
                    onChangeRaw={(_, date) => this.setState({ dataInicioReport: date, dataFimReport: null })}
                    onSelected={date => this.setState({ dataInicioReport: date, dataFimReport: null })} />
                </div>
                <div style={{ width: '7em', marginLeft: '25px' }}>
                  <NDatePickerInput
                    className={styles.datePicker}
                    require={(this.state.erroDatas || this.state.erroDataFinal)}
                    selected={dataFimReport}
                    value={this.state.dataFimReport}
                    minDate={dataInicioReport}
                    maxDate={moment()}
                    id="data_fim"
                    label={this.props.t("labels.endDate")}
                    onChange={date => this.setState({ dataFimReport: date })}
                    onChangeRaw={(_, date) => this.setState({ dataFimReport: date })}
                    onSelected={date => this.setState({ dataFimReport: date })} />
                </div>
              </NContainer>
            </div>
            : null}

          <br />
          <div className={'flex flex-just-end'} style={{ paddingTop: '1.5em' }} >
            <RaisedButton onClick={() => this.gerarRelatorioLotesInconsistentes()} backgroundColor='#2D4F7F' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100, marginRight: '1em' }} labelColor='#FFF' label={this.props.t("labels.generate", { ns: 'common' })} />
            <RaisedButton onClick={this.closeModal} backgroundColor='#FFF' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100 }} label={this.props.t("labels.cancel", { ns: 'common' })} />
          </div>
        </NForm>
      </div>
    );
  }

  formularioGargalo() {
    let sessionOpts = this.store.sessions.filter(s => s.stages.length);
    var opcoesUnidade = [];
    for (let i = 0; i < sessionOpts.length; i++) {
      opcoesUnidade.push({ text: sessionOpts[i].name, value: sessionOpts[i].id });
    }

    return (
      <div className={styles.relatorioLeadTime}>
        <NForm>
          <NFormTitle>{this.props.t("titles.bottleneckReport")}</NFormTitle>
          <div className={'flex flex-flow-col flex-just-between'} style={{ height: '5em', paddingTop: '-.5em' }}>
            <AutoComplete
              textFieldStyle={{ marginTop: '-16px', width: '294px' }}
              ref='unidadeIndustrial'
              underlineStyle={this.state.erroUnidadeIndustrial ? { borderColor: 'red' } : ''}
              onClick={() => this.setState({ unidadeIndustrialSelected: '', unidadeText: '' })}
              underlineFocusStyle={{ borderColor: '#000000' }}
              fullWidth={true}
              floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
              floatingLabelText={this.props.t("labels.industrialUnit")}
              title={this.props.t("labels.industrialUnit")}
              onUpdateInput={this.setUnidadeIndustrialSemResetarEquipamento.bind(this)}
              filter={AutoComplete.fuzzyFilter}
              dataSource={opcoesUnidade}
              popoverProps={{ className: 'autocomplete' }}
              openOnFocus={true}
              id='unidadeIndustrial' />
            {this.state.erroUnidadeIndustrial ? <div style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '4px' }} ref="validate">{this.props.t("errors.requiredField", { ns: 'validation' })}</div> : ''}
          </div>
          <div>
            <Checkbox
              label={this.props.t("labels.checkinCheckout")}
              onCheck={this.alternarAtualizacaoAutomatica}
              checked={this.state.desconsiderarCheckinCheckout}
              style={{ marginBottom: 10 }}
              iconStyle={{ fill: '#2b4d7c' }}
            />
          </div>
          <div style={{ fontFamily: 'Roboto', lineHeight: '1.15', fontSize: '14px', color: '#787878' }}>
            {this.props.t("labels.selectPeriod")}
          </div>

          <NContainer direction="horizontal">
            <div style={{ width: '7em' }}>
              <NDatePickerInput
                className={styles.datePicker}
                require={(this.state.erroDatas || this.state.erroDataInicial)}
                selected={this.state.start_date}
                value={this.state.start_date}
                maxDate={moment()} id="start_date" label={this.props.t("labels.startDate")}
                onChange={date => this.setState({ start_date: date, end_date: null })}
                onChangeRaw={(_, date) => this.setState({ start_date: date, end_date: null })} />
            </div>
            <div style={{ width: '7em', marginLeft: '25px' }}>
              <NDatePickerInput
                className={styles.datePicker}
                require={(this.state.erroDatas || this.state.erroDataFinal)}
                onChangeRaw={this._onDateChange}
                selected={this.state.end_date}
                value={this.state.end_date}
                minDate={this.state.start_date}
                maxDate={this.state.start_date != null ? moment(this.state.start_date).day(120).isAfter(moment()) ? moment() : moment(this.state.start_date).day(120) : moment()}
                id="end_date"
                label={this.props.t("labels.endDate")}
                onChange={date => this.setState({ end_date: date })} />
            </div>
          </NContainer>

          <div className={'flex flex-just-end'} style={{ paddingTop: '1.5em' }} >
            <RaisedButton onClick={() => this.gerarRelatorioGargalo()} backgroundColor='#2D4F7F' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100, marginRight: '1em' }} labelColor='#FFF' label={this.props.t("labels.generate", { ns: 'common' })} />
            <RaisedButton onClick={this.closeModal}
              backgroundColor='#FFF' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100 }} label={this.props.t("labels.cancel", { ns: 'common' })} />
          </div>
        </NForm>
      </div>
    );
  }

  formularioHoldingTime() {
    const { dataInicioReport, dataFimReport, maxDate = null, batchBit, errors } = this.state;
    let obj = '';

    let sessionOpts = this.store.sessions.filter(s => s.stages.length);
    var opcoesUnidade = [];
    for (let i = 0; i < sessionOpts.length; i++) {
      opcoesUnidade.push({ text: sessionOpts[i].name, value: sessionOpts[i].id });
    }

    return (
      <div className={styles.holding_time_report}>
        <NForm>
          <NFormTitle>{this.props.t("titles.holdingTimeDeviationReport")}</NFormTitle>
          <div className={'flex flex-flow-col flex-just-between'}>
            <AutoComplete
              textFieldStyle={{ marginTop: '-16px', width: '254px' }}
              ref='unidadeIndustrial'
              underlineStyle={errors.selecionar_unidade_industrial ? { borderColor: 'red' } : ''}
              onClick={() => this.setState({ unidadeIndustrialSelected: '', unidadeText: '' })}
              underlineFocusStyle={{ borderColor: '#000000' }}
              fullWidth={true}
              floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
              floatingLabelText={this.props.t("labels.industrialUnit")}
              title={this.props.t("labels.industrialUnit")}
              onUpdateInput={this.setUnidadeIndustrialSemResetarEquipamento.bind(this)}
              filter={AutoComplete.fuzzyFilter}
              dataSource={opcoesUnidade}
              popoverProps={{ className: 'autocomplete' }}
              openOnFocus={true}
              id='unidadeIndustrial' />
            {errors.selecionar_unidade_industrial ? <div style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '4px' }} ref="validate">{errors.selecionar_unidade_industrial}</div> : ''}
          </div>
          <br />

          <div style={{ marginTop: '-7px', marginBottom: '3px', fontFamily: 'Roboto', lineHeight: '1.15', fontSize: '14px', paddingBottom: '15px', color: '#787878', textTransform: 'uppercase' }}>
            {this.props.t("labels.batchType", { primaryReference: this.utils.getReferenciaLoteOrdem() })}
          </div>

          <div className={styles.ht_checkbox}>
            <Checkbox label={this.props.t("labels.processing")} labelStyle={{ color: '#5B5B5B' }} iconStyle={{ fill: '#2b4d7c' }} onCheck={this._handleCheck.bind(this, 1)} checked={isCheckedBit(batchBit, 1)} />
            <Checkbox label={this.props.t("labels.finished")} labelStyle={{ color: '#5B5B5B' }} iconStyle={{ fill: '#2b4d7c' }} onCheck={this._handleCheck.bind(this, 2)} checked={isCheckedBit(batchBit, 2)} />
          </div>
          <span style={{ color: 'red', fontSize: '11px' }}>{errors.selecionar_tipo_lote || ''}</span>
          <br />
          {
            isCheckedBit(batchBit, 2) ?
              <NContainer direction="horizontal">
                <NDatePicker id="minDate" required={!!errors.data_inicio_required} selected={dataInicioReport} label={this.props.t("labels.startDate")} onChange={date => {
                  this.setState(update(this.state, {
                    dataInicioReport: { $set: date }, end_date: { $set: null },
                    maxDate: { $set: moment(date).add(90, 'd') }, errors: {
                      data_inicio_required: { $set: undefined }
                    }
                  }));
                }} />
                <NDatePicker id="maxDate" required={!!errors.data_fim_required} selected={dataFimReport} minDate={dataInicioReport} maxDate={maxDate} label={this.props.t("labels.endDate")}
                  onChange={date => this.setState(update(this.state, {
                    dataFimReport: { $set: date },
                    errors: { data_fim_required: { $set: undefined } },
                  }))}
                />
              </NContainer>
              : null
          }
          <br />
          <div className={'flex flex-just-end'} style={{ paddingTop: '1.5em' }} >
            <RaisedButton onClick={() => this.gerarRelatorioHoldingTime()} backgroundColor='#2D4F7F' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100, marginRight: '1em' }} labelColor='#FFF' label={this.props.t("labels.generate", { ns: 'common' })} />
            <RaisedButton onClick={this.closeModal} backgroundColor='#FFF' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100 }} label={this.props.t("labels.cancel", { ns: 'common' })} />
          </div>
        </NForm>
      </div>
    );
  }

  formularioSetup() {
    let { eventos } = this.state;
    let sessionOpts = this.store.sessions.filter(s => s.stages.length);
    var opcoesUnidade = [];

    for (let i = 0; i < sessionOpts.length; i++) {
      opcoesUnidade.push({ text: sessionOpts[i].name, value: sessionOpts[i].id });
    }

    let sessions = JSON.parse(JSON.stringify(this.store.sessions));

    let grupo_equipamento = this.state.grupoEquipamentos.map(g => {
      return { value: g.id, text: g.nome };
    });

    let equipments = grupo_equipamento.concat([].concat.apply([],
      [].concat.apply([],
        sessions.map(s =>
          s.stages.map(ss => ss.equipments)))).reduce((prev, elem) => {
            if (!prev.map(p => p.value).includes(elem.id)) prev.push({ value: elem.id, text: elem.name });
            return prev;
          }, []).sort((a, b) => a.text < b.text ? -1 : a.text > b.text ? 1 : 0));

    const { dataInicioReport, dataFimReport, permissoes } = this.state;

    return (
      <div>
        <NForm style={{ maxWidth: '275px' }}>
          <NFormTitle>{this.props.t("titles.setupReport")}</NFormTitle>
          <AutoComplete
            textFieldStyle={{ marginTop: '-16px', width: '254px' }}
            ref='unidadeIndustrial'
            underlineStyle={this.state.erroUnidadeIndustrial ? { borderColor: 'red' } : ''}
            underlineFocusStyle={{ borderColor: '#000000' }}
            fullWidth={true}
            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
            floatingLabelText={this.props.t("labels.industrialUnit")}
            title={this.props.t("labels.industrialUnit")}
            onUpdateInput={this.setUnidadeIndustrialSemResetarEquipamento.bind(this)}
            filter={AutoComplete.fuzzyFilter}
            dataSource={opcoesUnidade}
            onClick={() => this.setState({ unidadeText: '', unidadeIndustrialSelected: '', lote: '', nomeProduto: '' })}
            openOnFocus={true}
            id='unidadeIndustrial'
            popoverProps={{ className: 'autocomplete' }} />
          {this.state.erroUnidadeIndustrial ?
            <div style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '4px' }} ref="validate">{this.props.t("errors.requiredField", { ns: 'validation' })}</div> :
            ''}
          <br />
          <AutoComplete
            textFieldStyle={{ width: '254px' }}
            ref='equipamento'
            underlineFocusStyle={{ borderColor: '#000000' }}
            floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
            floatingLabelText={this.props.t("labels.equipment")}
            title={this.props.t("labels.equipment")}
            fullWidth={true}
            onClick={() => this.setState({ equipamentoText: '', equipamentoSelected: '' })}
            onUpdateInput={this.setEquipamento.bind(this)}
            filter={AutoComplete.fuzzyFilter}
            dataSource={equipments}
            popoverProps={{ className: 'autocomplete' }}
            openOnFocus={true}
            id='equipamento'
          />
          <br />
          <div style={{ marginTop: '30px' }}>
            <MultiSelect
              isMulti
              options={eventos}
              allowSelectAll={false}
              placeholder={this.props.t("labels.events").toUpperCase()}
              onGetOptionLabel={(option) => option.text}
              onGetOptionValue={(option) => option.value}
              onSelectOption={this.handleEventos}
              value={this.state.eventosSelected}
            />
          </div>

          <div style={{ fontFamily: 'Roboto', lineHeight: '1.15', fontSize: '14px', color: '#787878', marginTop: '30px' }}>
            {this.props.t("labels.selectPeriod")}
          </div>

          <NContainer direction="horizontal">
            <div style={{ width: '7em' }}>
              <NDatePickerInput
                require={(this.state.erroDatas || this.state.erroDataInicial)}
                underLineError={(this.state.erroDatas || this.state.erroDataInicial)}
                className={styles.datePicker}
                selected={dataInicioReport}
                value={dataInicioReport}
                maxDate={moment()}
                id="start_date"
                label={this.props.t("labels.startDate")}
                onSelect={date => this.setState({ dataInicioReport: date, dataFimReport: null })}
                onChangeRaw={(_, date) => this.setState({ dataInicioReport: date, dataFimReport: null })}
                onChange={date => this.setState({ dataInicioReport: date, dataFimReport: null })}
                autoComplete="off" />
            </div>
            <div style={{ width: '7em', marginLeft: '25px' }}>
              <NDatePickerInput
                require={(this.state.erroDatas || this.state.erroDataFinal)}
                underLineError={(this.state.erroDatas || this.state.erroDataFinal)}
                className={styles.datePicker}
                selected={dataFimReport}
                value={dataFimReport}
                minDate={dataInicioReport}
                id="end_date"
                label={this.props.t("labels.endDate")}
                maxDate={dataInicioReport != null ?
                  moment(dataInicioReport).add(3, 'months').subtract(1, 'days').isAfter(moment()) ?
                    moment() :
                    moment(dataInicioReport).add(3, 'months').subtract(1, 'days') :
                  moment()}
                onSelect={date => this.setState({ dataFimReport: date })}
                onChangeRaw={(_, date) => this.setState({ dataFimReport: date })}
                onChange={date => this.setState({ dataFimReport: date })}
                autoComplete="off" />
            </div>
          </NContainer>
          <br />
          {
            permissoes.rampUp ?
              <>
                <Divider />
                <h3 style={{ paddingTop: "1.5em", paddingBottom: ".5em", fontSize: "0.8em", textTransform: 'uppercase' }}>
                  {this.props.t("labels.showOnReport")}
                </h3>
                <br />
                <Checkbox
                  label={this.props.t("labels.ramp", { ns: 'common' })}
                  checked={isCheckedBit(this.state.infoRampUpChecked)}
                  onCheck={(_event, value) => {
                    if (value) Mixpanel.track('SETUP_RAMP_UP')
                    this.setState({ infoRampUpChecked: value, periodoReport: 1 })
                  }}
                  style={{ width: "100%", fontSize: '90%' }}
                  iconStyle={{ fill: this.state.erroSessaoUm ? "#F00" : "#2b4d7c", marginRight: 10 }}
                  disabled={this.state.equipamentoSelected.length === 0}
                />
                {this.state.infoRampUpChecked ?
                  <>
                    <br />
                    <div>
                      <div className='flex flex-dir-column flex-align-center'>
                        <div className='flex flex-dir-row flex-just-between flex-align-center'>
                          <label className="control control-radio"
                            style={{
                              height: "1.2em",
                              width: 115,
                              textAlign: "left",
                              paddingTop: 9
                            }}>
                            <span style={{ marginLeft: "2em", width: 300 }}>
                              {this.props.t("labels.duration", { ns: 'common' })}
                            </span>
                            <input type="radio" checked={this.state.periodoReport == 1} name="periodo" />
                            <div data-value='1' onClick={this.radioButton} className="control_indicator"></div>
                          </label>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <TextField
                            name="nome"
                            style={{ width: '40%' }}
                            disabled={this.state.periodoReport != 1}
                            required={this.state.periodoReport != 1}
                            value={this.state.rampUpHour}
                            onChange={(event, value) => this.setState({ rampUpHour: MaskHoraMinutoSegundo(value), errorRampUpPercent: false })}
                          />
                          {this.state.errorRampUpHour ?
                            <div
                              style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '4px' }}
                              ref="validate">{this.props.t("errors.requiredField", { ns: 'validation' })}</div> : ''}
                        </div>
                      </div>
                      <div className='flex flex-dir-column flex-align-center'>
                        <div className='flex flex-dir-row flex-just-between flex-align-center'>
                          <label className="control control-radio"
                            style={{
                              height: "1.2em",
                              width: 140,
                              textAlign: "left",
                              paddingTop: 9
                            }}>
                            <span style={{ marginLeft: "2em", width: 300 }}>
                              {this.props.t("labels.percent", { ns: 'common' })}
                            </span>
                            <input type="radio" checked={this.state.periodoReport == 2} name="periodo" />
                            <div data-value='2' onClick={this.radioButton} className="control_indicator"></div>
                          </label>
                        </div>
                        <div>
                        </div >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <TextField
                            name="nome"
                            style={{ width: '30%' }}
                            disabled={this.state.periodoReport != 2}
                            required={this.state.periodoReport != 2}
                            value={this.state.rampUpPercent}
                            onChange={(event, value) => {
                              value = value.replace(/[^0-9]/g, '');

                              value = value.slice(0, 3);

                              value += '%';

                              this.setState({ rampUpPercent: value });

                              if (this.state.errorRampUpHour) {
                                this.setState({ errorRampUpHour: false })
                              }
                            }
                            }
                          />
                          {this.state.errorRampUpPercent ?
                            <div
                              style={{ color: 'red', fontSize: '12px', lineHeight: '12px', position: 'relative', top: '4px' }}
                              ref="validate">{this.props.t("errors.requiredField", { ns: 'validation' })}</div> : ''}
                        </div>
                      </div>
                    </div>
                  </>
                  : null
                }
                <br />
              </>
              :
              null
          }
          <Checkbox
            label={this.props.t("labels.smartSetupStages", { ns: 'reports' })}
            checked={isCheckedBit(this.state.infoSmartSetupChecked)}
            onCheck={(_event, value) => this.setState({ infoSmartSetupChecked: value })}
            style={{ width: "100%", fontSize: '90%', textTransform: 'uppercase' }}
            iconStyle={{ fill: this.state.erroSessaoUm ? "#F00" : "#2b4d7c", marginRight: 10 }}
          />
          <br />
          <NButtonGroup>
            <RaisedButton onClick={this.gerarRelatorioSetup} backgroundColor='#2D4F7F' buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100 }} labelColor='#FFF' label={this.props.t("labels.generate", { ns: 'common' })} />
            <RaisedButton onClick={this.closeModal} buttonStyle={{ fontFamily: '' }} style={{ fontWeigth: 100, marginLeft: '.5em' }} label={this.props.t("labels.cancel", { ns: 'common' })} />
          </NButtonGroup>
        </NForm>
      </div>
    );
  }

  alternarAtualizacaoAutomatica(event, value) {
    this.setState({
      desconsiderarCheckinCheckout: value
    });
  }

  alternarFiltros(e) {
    e.preventDefault();
    return this.setState({
      checkedUnidadeIndustrialInput: !this.state.checkedUnidadeIndustrialInput,
      checkedProdutoInput: !this.state.checkedProdutoInput,
      unidadeIndustrialSelected: {},
      produtoFiltro: {}
    });
  }


  divRelatorioCadastroLote() {
    const { dataInicioReport, dataFimReport } = this.state;
    let sessionOpts = this.store.sessions.filter(s => s.stages.length);
    let opcoesUnidade = [];

    for (let i = 0; i < sessionOpts.length; i++) {
      opcoesUnidade.push({ text: sessionOpts[i].name, value: sessionOpts[i].id });
    }

    let productsReport = this.state.produtos.map(produto => {
      return { value: produto.id, text: produto.sku + ' - ' + produto.name };
    });
    return (
      <div style={{
        width: '300px',
        height: !this.state.checkedEncerradas &&
          !this.state.checkedDescartadas ? '70px' : '185px',
        marginTop: '50px'
      }}>
        <div style={{ fontFamily: 'Roboto', lineHeight: '1.15', fontSize: '14px', color: '#787878', marginTop: '20px' }}>
          {this.props.t("labels.batchType", { primaryReference: this.utils.getReferenciaLoteOrdem() })}
        </div>
        <div style={{ marginTop: 5 }}>
          <Checkbox
            style={{ marginBottom: 10 }}
            label={this.props.t("labels.processing")}
            checked={this.state.checkedEmProcessamento}
            onCheck={(_event, value) => this.setState({ checkedEmProcessamento: value })}
            iconStyle={{ fill: this.state.erroInformacoes ? '#F00' : '#2b4d7c', marginRight: 10 }}
          />
          <Checkbox
            style={{ marginBottom: 10 }}
            label={this.props.t("labels.finished")}
            checked={this.state.checkedEncerradas}
            onCheck={(_event, value) => this.setState({ checkedEncerradas: value })}
            iconStyle={{ fill: this.state.erroInformacoes ? '#F00' : '#2b4d7c', marginRight: 10 }}
          />
          <Checkbox
            style={{ marginBottom: 10 }}
            label={this.props.t("labels.discarded")}
            checked={this.state.checkedDescartadas}
            onCheck={(_event, value) => this.setState({ checkedDescartadas: value })}
            iconStyle={{ fill: this.state.erroInformacoes ? '#F00' : '#2b4d7c', marginRight: 10 }}
          />

        </div>

        {
          !this.state.checkedEncerradas &&
            !this.state.checkedDescartadas ?

            !this.state.checkedEmProcessamento
              ?
              <span style={{ color: 'red', fontSize: '14px' }}>{this.props.t("errors.noFilterSelected")}</span>
              :
              null
            :
            <div>
              <div style={{ fontFamily: 'Roboto', lineHeight: '1.15', fontSize: '14px', color: '#787878', marginTop: '20px' }}>
                {this.props.t("labels.selectPeriod")}
              </div>

              <NContainer direction="horizontal" style={{ justifyContent: 'space-between' }}>
                <div style={{ width: '7em' }}>
                  <NDatePickerInput
                    className={styles.datePicker}
                    require={(this.state.erroDatas || this.state.erroDataInicial)}
                    selected={dataInicioReport}
                    value={dataInicioReport}
                    maxDate={moment()}
                    label={this.props.t("labels.startDate")}
                    onChange={date => this.setState({ dataInicioReport: date, dataFimReport: null, maxDate: moment(date).add(3, 'months') })}
                    onChangeRaw={(_, date) => this.setState({ dataInicioReport: date, dataFimReport: null, maxDate: moment(date).add(3, 'months') })} />
                </div>
                <div style={{ width: '7em', marginLeft: '55px' }}>
                  <NDatePickerInput
                    className={styles.datePicker}
                    require={(this.state.erroDatas || this.state.erroDataFinal)}
                    selected={dataFimReport}
                    value={dataFimReport}
                    minDate={dataInicioReport}
                    id="end_date" label={this.props.t("labels.endDate")}
                    maxDate={moment(dataInicioReport).add(3, 'months') > moment() ? moment() : this.state.maxDate || moment()}
                    onChange={date => this.setState({ dataFimReport: date })}
                    onChangeRaw={(_, date) => this.setState({ dataFimReport: date })} />
                </div>
              </NContainer>
            </div>
        }
        
      </div>
    );
  }

  divRelatorioVinculo() {
    const { dataInicioReport, dataFimReport } = this.state;
    let sessionOpts = this.store.sessions.filter(s => s.stages.length);
    let opcoesUnidade = [];

    for (let i = 0; i < sessionOpts.length; i++) {
      opcoesUnidade.push({ text: sessionOpts[i].name, value: sessionOpts[i].id });
    }

    return (
      <div style={{
        width: '300px',
        height: '70px',
        marginTop: '50px'
      }}>
        {
          !this.state.checkedEncerradas &&
            !this.state.checkedDescartadas ?

            !this.state.checkedEmProcessamento
              ?
              <span style={{ color: 'red', fontSize: '14px' }}>{this.props.t("errors.noFilterSelected")}</span>
              :
              null
            :
            <div>
              <div style={{ fontFamily: 'Roboto', lineHeight: '1.15', fontSize: '14px', color: '#787878', marginTop: '20px' }}>
                {this.props.t("labels.selectPeriodOrderLink")}
              </div>

              <NContainer direction="horizontal" style={{ justifyContent: 'space-between' }}>
                <div style={{ width: '7em' }}>
                  <NDatePickerInput
                    className={styles.datePicker}
                    require={(this.state.erroDatas || this.state.erroDataInicial)}
                    selected={dataInicioReport}
                    value={dataInicioReport}
                    maxDate={moment()}
                    label={this.props.t("labels.startDate")}
                    onChange={date => this.setState({ dataInicioReport: date, dataFimReport: null, maxDate: moment(date).add(3, 'months') })}
                    onChangeRaw={(_, date) => this.setState({ dataInicioReport: date, dataFimReport: null, maxDate: moment(date).add(3, 'months') })} />
                </div>
                <div style={{ width: '7em', marginLeft: '55px' }}>
                  <NDatePickerInput
                    className={styles.datePicker}
                    require={(this.state.erroDatas || this.state.erroDataFinal)}
                    selected={dataFimReport}
                    value={dataFimReport}
                    minDate={dataInicioReport}
                    id="end_date" label={this.props.t("labels.endDate")}
                    maxDate={moment(dataInicioReport).add(3, 'months') > moment() ? moment() : this.state.maxDate || moment()}
                    onChange={date => this.setState({ dataFimReport: date })}
                    onChangeRaw={(_, date) => this.setState({ dataFimReport: date })} />
                </div>
              </NContainer>
            </div>
        }
        
      </div>
    );
  }

  formularioRelatorioCadastro() {
    const funcionalidades = [
      { text: this.props.t("labels.routes"), value: 'rota' },
      { text: this.props.t("labels.products"), value: 'produto' },
      { text: this.props.t("labels.orderLink"), value: 'vinculo-ordem' },
      (this.state.permissoes.cadastroLote ? { text: this.utils.getReferenciaLoteOrdem(), value: "lote" } : null),
      (this.state.permissoes.cadastroEquipamento ? { text: this.props.t("labels.equipment_many"), value: 'equipamento' } : null),
      (this.state.permissoes.cadastroUsuario ? { text: this.props.t("labels.users"), value: 'usuarios' } : null),
    ];

    return (
      <div>
        <NForm>
          <NFormTitle>{this.props.t("titles.formsReport")}</NFormTitle>
          <div className={'flex flex-flow-col flex-just-between'} style={{ height: '1em', paddingTop: '-.5em' }}>
            <AutoComplete
              textFieldStyle={{ marginTop: '-16px', width: '236px' }}
              underlineStyle={''}
              dataSource={funcionalidades}
              onClick={() => this.setState({ searchTextFuncionalidade: '' })}
              underlineFocusStyle={{ borderColor: '#000000' }}
              fullWidth={true}
              floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
              floatingLabelText={this.props.t("labels.feature")}
              title={this.props.t("labels.feature")}
              filter={AutoComplete.fuzzyFilter}
              popoverProps={{ className: 'autocomplete' }}
              openOnFocus={true}
              onUpdateInput={(searchText, dataSource) => {
                let opcaoSelecionada = dataSource.find(item => item && item.text === searchText);

                this.setState({
                  funcionalidadeSelecionada: opcaoSelecionada.value,
                  searchTextFuncionalidade: opcaoSelecionada.text,
                });
              }}
            />
          </div>
          {this.state.funcionalidadeSelecionada === 'lote' ?
            this.divRelatorioCadastroLote() :
            null
          }

          {this.state.funcionalidadeSelecionada === 'vinculo-ordem' ?
            this.divRelatorioVinculo() :
            null
          }

          <div style={{ margin: '4em 0 0 0' }}>
            <NButtonGroup >
              <RaisedButton
                onClick={this.gerarRelatorioCadastro}
                backgroundColor="#2D4F7F"
                labelColor="#FFF"
                label={this.props.t("labels.generate", { ns: 'common' })} />

              <RaisedButton
                onClick={this.closeModal}
                label={this.props.t("labels.cancel", { ns: 'common' })} />
            </NButtonGroup>
          </div>
        </NForm>
      </div>
    );
  }

  async gerarRelatorioCadastro() {
    if (!this.state.funcionalidadeSelecionada) return alertBox(this.props.t("errors.noFeatureSelected"), this.props.t("labels.warning", { ns: 'common' }), 'warning');

    let dataInicioReportFinal, dataFimReportFinal;
    const { dataInicioReport, dataFimReport } = this.state;
    let error;

    logAcesso(`RELATORIO_CADASTRO_${this.state.funcionalidadeSelecionada.toUpperCase()}`);
    let url = this.endpoints.RELATORIO_CADASTRO + `/${this.state.funcionalidadeSelecionada}`;
    if (this.state.funcionalidadeSelecionada === 'lote') {
      let params = [];

      if (this.state.checkedDescartadas || this.state.checkedEncerradas) {
        if (!dataInicioReport) {
          this.showAlert(this.props.t("errors.requiredStartDate"), this.props.t("labels.warning", { ns: 'common' }), 'warning');
          error = true;
        } else if (!dataFimReport) {
          this.showAlert(this.props.t("errors.requiredEndDate"), this.props.t("labels.warning", { ns: 'common' }), 'warning');
          error = true;
        } else {
          this.setState({
            erroDataFimReport: false,
            erroDataInicioReport: false
          });
          error = false;
          dataInicioReportFinal = dataInicioReport;
          dataFimReportFinal = dataFimReport;
        }
      }
      else if (this.state.checkedEmProcessamento) {
        dataInicioReportFinal = new Date('01/01/0001 00:00:00'),
          dataFimReportFinal = moment();
      } else if (!this.state.checkedEmProcessamento) {
        error = true;
        alertBox(this.props.t("errors.noFilterSelected"), this.props.t("labels.error", { ns: 'common' }), 'error');
      }

      params.push(moment(dataInicioReportFinal, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss'));
      params.push(moment(dataFimReportFinal, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY').concat(' 23:59:59'));
      params.push(Utils.getReferenciaLotesOrdens());
      params.push(this.state.checkedEmProcessamento);
      params.push(this.state.checkedEncerradas);
      params.push(this.state.checkedDescartadas);

      url = url + '/' + params.join('/');
    }
    
    if(this.state.funcionalidadeSelecionada === 'vinculo-ordem') {
      let params = [];
      if (!dataInicioReport) {
        this.showAlert(this.props.t("errors.requiredStartDate"), this.props.t("labels.warning", { ns: 'common' }), 'warning');
        error = true;
      } else if (!dataFimReport) {
        this.showAlert(this.props.t("errors.requiredEndDate"), this.props.t("labels.warning", { ns: 'common' }), 'warning');
        error = true;
      } 
      dataInicioReportFinal = dataInicioReport;
      dataFimReportFinal = dataFimReport;

      params.push(moment(dataInicioReportFinal, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss'));
      params.push(moment(dataFimReportFinal, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY').concat(' 23:59:59'));
      params.push("Ordens");

      url = url + '/' + params.join('/');


    }
    if (!error) {
      this.openModal('loading_relatorio');
      await requestRelatorioXLSX(url, this.props.t("labels.formReportTitle", { feature: this.state.searchTextFuncionalidade.toLowerCase() })).then(() => {
        this.closeModal();
      }).catch((e) => {
        console.log(e);
        this.setState((state) =>
        ({
          modal: true,
          tipoModal: e.status,
          erroMSG: e.mensagem || this.props.t("errors.cannotGenerateReport"),
          searchTextFuncionalidade: '',
          funcionalidadeSelecionada: '',
        })
        );
      });

      this.setState({
        errors: {},
        searchTextFuncionalidade: '',
        funcionalidadeSelecionada: '',
      });

    }
  }

  formularioRelatorioFluxo() {
    const funcionalidades = [
      { text: this.utils.getReferenciaLotesOrdens(), value: 'ordem' }
    ];

    return (
      <div>
        <NForm>
          <NFormTitle>{this.props.t("titles.flowReport")}</NFormTitle>

          <div className={'flex flex-flow-col flex-just-between'} style={{ height: '5em', paddingTop: '-.5em' }}>
            <AutoComplete
              textFieldStyle={{ marginTop: '-16px', width: '236px' }}
              underlineStyle={''}
              onClick={() => this.setState({ searchTextFuncionalidade: '' })}
              underlineFocusStyle={{ borderColor: '#000000' }}
              fullWidth={true}
              floatingLabelStyle={{ color: '#5B5B5B', textTransform: 'uppercase' }}
              floatingLabelText={this.props.t("labels.feature")}
              title={this.props.t("labels.feature")}
              onUpdateInput={(searchText, dataSource) => {
                let opcaoSelecionada = dataSource.find(item => item.text === searchText);

                this.setState({
                  funcionalidadeSelecionada: opcaoSelecionada.value,
                  searchTextFuncionalidade: opcaoSelecionada.text,
                });
              }}
              filter={AutoComplete.fuzzyFilter}
              dataSource={funcionalidades}
              popoverProps={{ className: 'autocomplete' }}
              openOnFocus={true}
            />
          </div>

          <NButtonGroup>
            <RaisedButton
              onClick={this.gerarRelatorioFluxo}
              backgroundColor="#2D4F7F"
              labelColor="#FFF"
              label={this.props.t("labels.generate", { ns: 'common' })} />

            <RaisedButton
              onClick={this.closeModal}
              label={this.props.t("labels.cancel", { ns: 'common' })} />
          </NButtonGroup>
        </NForm>
      </div>
    );
  }

  async gerarRelatorioFluxo() {
    if (!this.state.funcionalidadeSelecionada) return alertBox(this.props.t("errors.noFeatureSelected"), this.props.t("labels.warning", { ns: 'common' }), 'warning');
    this.openModal('loading_relatorio');
    logAcesso(`RELATORIO_FLUXO_${this.state.funcionalidadeSelecionada.toUpperCase()}`);
    let url = this.endpoints.RELATORIO_FLUXO + `/${this.state.funcionalidadeSelecionada}`;

    await requestRelatorioXLSX(url, this.props.t("titles.flowReportWithReference", { primaryReference: this.state.searchTextFuncionalidade.toLowerCase() })).then(() => {
      this.closeModal();
    }).catch(() => {
      this.setState((state) =>
      ({
        modal: true,
        tipoModal: 'erro',
        erroMSG: this.props.t("errors.cannotGenerateReport"),
        searchTextFuncionalidade: '',
        funcionalidadeSelecionada: '',
      })
      );
    });

    this.setState({
      errors: {}
    });
  }

  handleComplete(complete, value, index) {
    let objValue = index.find(i => i.text == value);
    this.setState({
      [complete]: objValue
    });
  }

  errorMessage(support = true) {
    return (
      <div className="modal" style={{ width: '500px', height: '100%' }}>
        <div>
          <div style={{ textAlign: 'left', fontWeight: 'bold' }} >
            {this.props.t("labels.warning", { ns: 'common' })}
          </div>
          <div>
            <i className="fa fa-close cursor-pointer" onClick={() => this.closeModal()} />
          </div>
        </div>
        <div style={{ textAlign: 'left' }} >
          {this.state.erroMSG}
        </div>
        {
          support ?
            <div style={{ paddingTop: '1em' }} >
              E-mail: <a style={{ color: '#2196F3' }} href="mailto:suporte@cogtive.com.br"> suporte@cogtive.com.br </a>
            </div>
            :
            null
        }
        <NButtonGroup>
          <NButton style={{ backgroundColor: '#2D4F7F', color: 'white' }} onClick={() => this.closeModal()}>OK</NButton>
        </NButtonGroup>
      </div>
    );
  }

  contentModal() {
    let { tipoModal } = this.state;

    let sessions = JSON.parse(JSON.stringify(this.store.sessions));

    let grupo_equipamento = this.state.grupoEquipamentos.map(g => {
      return { value: g.id, text: g.nome, ehGrupo: true };
    });

    let equipments = grupo_equipamento.concat([].concat.apply([],
      [].concat.apply([],
        sessions.map(s =>
          s.stages.map(ss => ss.equipments)))).reduce((prev, elem) => {
            if (!prev.map(p => p.value).includes(elem.id)) prev.push({ value: elem.id, text: elem.name, ehGrupo: elem.ehGrupo });
            return prev;
          }, []).sort((a, b) => a.text < b.text ? -1 : a.text > b.text ? 1 : 0));

    let produtos = this.state.produtos.map(produto => {
      return { value: produto.id, text: produto.sku + ' - ' + produto.name };
    });

    switch (tipoModal) {
      case 'apontamento':
        return <ModalApontamentos closeModal={this.closeModal} openModal={this.openModal} listEquipments={equipments} />;

      case 'desvio_rota':
        return this.formularioDesvioRota();

      case 'oee':
        return <ModalRelatorioOee closeModal={this.closeModal} openModal={this.openModal} />

      case 'lead_time':
        return this.formularioLeadTime();

      case 'lotes_inconsistente':
        return this.formularioLotesInconsistentes();

      case 'gargalo':
        return this.formularioGargalo();

      case 'holding_time':
        return this.formularioHoldingTime();

      case 'setup':
        return this.formularioSetup();

      case 'cadastro':
        return this.formularioRelatorioCadastro();

      case 'loading_relatorio':
        return <div className="modal" id={styles.loading_relatorio} style={{ width: '250px', height: '100%' }}>
          <div className={'flex flex-just-center'} style={{ paddingBottom: '20px', paddingTop: '10px' }}>
            <i className="material-icons" style={{ fontSize: '2.7em', paddingTop: '15px' }}>settings</i>
            <i className="material-icons" style={{ fontSize: '2.2em', transform: 'rotate(36deg)', position: 'relative', right: '10px', paddingTop: '3px' }}>settings</i>
          </div>
          <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
            <h3 style={{ fontSize: '1.2em', fontWeight: '600' }}>{this.props.t("labels.generatingReport")}</h3>
          </div>
          <LinearProgress mode="indeterminate" color='#2D4F7F' size={60} thickness={5} style={{ marginBottom: '5px', paddingBottom: '3px' }} />
        </div>;

      case 'audit_trail':
        return (
          <ModalAuditTrail
            closeModal={this.closeModal}
            openModal={this.openModal}
            setErrorMessage={this.setErrorMessage} />
        );

      case 'cadastro':
        return this.formularioRelatorioCadastro();

      case 'oee_by_order':
        return <ModalRelatorioOeePorOrdem
          closeModal={this.closeModal}
          openModal={this.openModal}
          listEquipments={equipments}
          listSkus={produtos} />;

      case 'fluxo':
        return this.formularioRelatorioFluxo();

      case 'erro':
        return this.errorMessage();

      case 'warning':
        return this.errorMessage(false);

      case 'gaps':
        return <ModalRelatorioGaps closeModal={this.closeModal} />

      case 'anomalias':
        return <ModalRelatorioAnomalias closeModal={this.closeModal} />

      case 'sensores':
        return <ModalRelatorioSensores closeModal={this.closeModal} />

      case 'pareto':
        return <ModalRelatorioPareto closeModal={this.closeModal} openModal={this.openModal} />
    }
  }

  setErrorMessage(message) {
    this.setState({
      erroMSG: message
    });
  }

  openModalApontamentos = () => {
    this.openModal('apontamento', 'RELATORIO_APONTAMENTOS');
    Mixpanel.track('RELATORIO_APONTAMENTOS');
  }

  openModalDesvioRota = () => {
    this.openModal('desvio_rota', 'RELATORIO_DESVIO_ROTA')
    Mixpanel.track('RELATORIO_DESVIO_ROTA');
  }

  openModalOEE = () => {
    this.openModal('oee', 'RELATORIO_OEE')
    Mixpanel.track('RELATORIO_OEE');
  }

  openModalOEEOrdem = () => {
    this.openModal('oee_by_order', 'RELATORIO_OEE_POR_ORDEM')
    Mixpanel.track('RELATORIO_OEE_POR_ORDEM');
  }

  openModalLeadTime = () => {
    this.openModal('lead_time', 'RELATORIO_LEAD_TIME')
    Mixpanel.track('RELATORIO_LEAD_TIME');
  }

  openModalLotes = () => {
    this.openModal('lotes_inconsistente', 'RELATORIO_LOTES_INCONSISTENTE')
    Mixpanel.track('RELATORIO_LOTES_INCONSISTENTE');
  }

  openModalGargalo = () => {
    this.openModal('gargalo', 'RELATORIO_GARGALO')
    Mixpanel.track('RELATORIO_GARGALO');
  }

  openModalHoldingTime = () => {
    this.openModal('holding_time', 'RELATORIO_DESVIO_HOLDING_TIME')
    Mixpanel.track('RELATORIO_DESVIO_HOLDING_TIME');
  }

  openModalCadastros = () => {
    this.openModal('cadastro', 'RELATORIO_CADASTRO')
    Mixpanel.track('RELATORIO_CADASTRO');
  }

  openModalSetup = () => {
    this.openModal('setup', 'RELATORIO_SETUP')
    Mixpanel.track('RELATORIO_SETUP');
  }

  openModalAuditTrail = () => {
    this.openModal('audit_trail', 'RELATORIO_AUDIT_TRAIL')
    Mixpanel.track('RELATORIO_AUDIT_TRAIL');
  }

  openModalFluxo = () => {
    this.openModal('fluxo', 'RELATORIO_FLUXO')
    Mixpanel.track('RELATORIO_FLUXO');
  }

  openModalGaps = () => {
    this.openModal('gaps', 'RELATORIO_GAPS')
    Mixpanel.track('RELATORIO_GAPS');
  }

  openModalAnomalias = () => {
    this.openModal('anomalias', 'RELATORIO_ANOMALIAS')
    Mixpanel.track('RELATORIO_ANOMALIAS');
  }

  openModalSensores = () => {
    this.openModal('sensores', 'RELATORIO_SENSORES')
    Mixpanel.track('RELATORIO_SENSORES');
  }

  openModalPareto = () => {
    this.openModal('pareto', 'RELATORIO_PARETO')
    Mixpanel.track('RELATORIO_PARETO');
  }

  render() {
    let { permissoes } = this.state;

    if (this.state.loadingPage) {
      return (
        <Container sectionStyle={{ height: '94vh' }}>
          <div className={'loading-wrapper'}>
            <MDSpinner singleColor='#2D4F7F' size={60} borderSize={7} />
          </div>
        </Container>
      );
    } else {
      return (
        <Container modal={this.state.modal} contentModal={this.contentModal} sectionStyle={{ width: '100%', overflow: 'auto', height: '100%' }}>
          <div className={styles.container}>
            <div>
              <h2 className={styles.title}> {this.props.t("labels.reports")} </h2>
              <div className={styles.column_card}>
                <div className={styles.row_card}>
                  <Paper zDepth={2} className={permissoes.apontamento ? styles.option : styles.disable} onClick={permissoes.apontamento ? () => this.openModalApontamentos() : () => { }}>
                    <div className={styles.card}>
                      <i style={{ fontSize: '3em', color: '#7E7E7E' }} className='material-icons'>screen_share</i>
                      <div className={styles.nome_card}> {this.props.t("labels.notes")} </div>
                    </div>
                  </Paper>

                  <Paper zDepth={2} className={permissoes.desvioRota ? styles.option : styles.disable} onClick={permissoes.desvioRota ? () => this.openModalDesvioRota() : () => { }}>
                    <div className={styles.card}>
                      <i style={{ fontSize: '3em', color: '#7E7E7E' }} className='material-icons'>call_split</i>
                      <div className={styles.nome_card}> {this.props.t("labels.routeDeviation")} </div>
                    </div>
                  </Paper>

                  <Paper zDepth={2} className={permissoes.oee ? styles.option : styles.disable} onClick={permissoes.oee ? () => this.openModalOEE() : () => { }}>
                    <div className={styles.card}>
                      <i style={{ fontSize: '3em', color: '#7E7E7E' }} className='material-icons'>multiline_chart</i>
                      <div className={styles.nome_card}> {this.props.t("labels.oee")} </div>
                    </div>
                  </Paper>

                  <Paper zDepth={2} className={permissoes.oeePorOdem ? styles.option : styles.disable} onClick={permissoes.oeePorOdem ? () => this.openModalOEEOrdem() : () => { }}>
                    <div className={styles.card}>
                      <i style={{ fontSize: '3em', color: '#7E7E7E' }} className='material-icons'>show_chart</i>
                      <div className={styles.nome_card} style={{ fontSize: ".95em" }} > {this.props.t("labels.oeeByOrder", { primaryReference: this.utils.getReferenciaLoteOrdem() })} </div>
                    </div>
                  </Paper>
                </div>

                <div className={styles.row_card}>

                  <Paper zDepth={2} className={permissoes.leadTime ? styles.option : styles.disable} onClick={permissoes.leadTime ? () => this.openModalLeadTime() : () => { }}>
                    <div className={styles.card}>
                      <i style={{ fontSize: '3em', color: '#7E7E7E' }} className='material-icons'>info</i>
                      <div className={styles.nome_card}> {this.props.t("labels.leadTime")} </div>
                    </div>
                  </Paper>

                  <Paper zDepth={2} className={permissoes.loteInconsistente ? styles.option : styles.disable} onClick={permissoes.loteInconsistente ? () => this.openModalLotes() : () => { }}>
                    <div className={styles.card}>
                      <i className="fa fa-chain-broken" style={{ fontSize: '3em', color: '#7E7E7E' }}></i>
                      <div className={styles.nome_card}> {this.props.t("labels.inconsistent")} </div>
                    </div>
                  </Paper>

                  <Paper zDepth={2} className={permissoes.gargalo ? styles.option : styles.disable} onClick={permissoes.gargalo ? () => this.openModalGargalo() : () => { }}>
                    <div className={styles.card}>
                      <img width={80} src='../assets/iconGargalo.svg' />
                      <div className={styles.nome_card}> {this.props.t("labels.bottleneck")} </div>
                    </div>
                  </Paper>

                  <Paper zDepth={2} className={permissoes.holdingTime ? styles.option : styles.disable} onClick={permissoes.holdingTime ? () => this.openModalHoldingTime() : () => { }}>
                    <div className={styles.card}>
                      <i style={{ fontSize: '3em', color: '#7E7E7E' }} className='material-icons'>timer</i>
                      <div className={styles.nome_card}> {this.props.t("labels.holdingTime")} </div>
                    </div>
                  </Paper>
                </div>

                <div className={styles.row_card}>

                  <Paper zDepth={2} className={permissoes.cadastro ? styles.option : styles.disable} onClick={permissoes.cadastro ? () => this.openModalCadastros() : () => { }}>
                    <div className={styles.card}>
                      <img width={55} src='../assets/relatorio-cadastro.svg' />
                      <div className={styles.nome_card}> {this.props.t("labels.forms")} </div>
                    </div>
                  </Paper>

                  <Paper zDepth={2} className={permissoes.setup ? styles.option : styles.disable} onClick={permissoes.setup ? () => this.openModalSetup() : () => { }}>
                    <div className={styles.card}>
                      <img src={'./assets/maintenceDark.svg'} width={48} height={48} title={'Setup'} />
                      <div className={styles.nome_card}> {this.props.t("labels.setup")} </div>
                    </div>
                  </Paper>

                  <Paper zDepth={2} className={permissoes.auditTrail ? styles.option : styles.disable} onClick={permissoes.auditTrail ? () => this.openModalAuditTrail() : () => { }}>
                    <div className={styles.card}>
                      <i style={{ fontSize: '3em', color: '#7E7E7E' }} className='material-icons'>search</i>
                      <div className={styles.nome_card}> {this.props.t("labels.auditTrail")} </div>
                    </div>
                  </Paper>

                  <Paper zDepth={2} className={permissoes.fluxo ? styles.option : styles.disable} onClick={permissoes.fluxo ? () => this.openModalFluxo() : () => { }}>
                    <div className={styles.card}>
                      <img src={'./assets/settings_ethernet.svg'} width={48} height={48} title={'Fluxo'} />
                      <div className={styles.nome_card}> {this.props.t("labels.flow")} </div>
                    </div>
                  </Paper>
                </div>

                <div className={styles.row_card}>
                  <Paper zDepth={2} className={permissoes.apontamento ? styles.option : styles.disable} onClick={permissoes.apontamento ? () => this.openModalGaps() : () => { }}>
                    <div className={styles.card}>
                      <img src={'./assets/gap-icon.svg'} width={48} height={48} title={this.props.t("labels.gaps")}
                        style={{ filter: "invert(42%) sepia(68%) saturate(0%) hue-rotate(244deg) brightness(102%) contrast(85%)" }} />
                      <div className={styles.nome_card}> {this.props.t("labels.gaps")} </div>
                    </div>
                  </Paper>

                  <Paper zDepth={2} className={permissoes.anomalies ? styles.option : styles.disable} onClick={permissoes.anomalies ? () => this.openModalAnomalias() : () => { }}>
                    <div className={styles.card}>
                      <img src={'./assets/anomaly-icon.svg'} width={48} height={48} title={this.props.t("labels.anomalies")}
                        style={{ filter: "invert(42%) sepia(68%) saturate(0%) hue-rotate(244deg) brightness(102%) contrast(85%)" }} />
                      <div className={styles.nome_card}> {this.props.t("labels.anomalies")} </div>
                    </div>
                  </Paper>

                  <Paper zDepth={2} className={permissoes.sensors ? styles.option : styles.disable} onClick={permissoes.sensors ? () => this.openModalSensores() : () => { }}>
                    <div className={styles.card}>
                      <img src={'./assets/sensor-icon.svg'} width={48} height={48} title={this.props.t("labels.sensors")}
                        style={{ filter: "invert(42%) sepia(68%) saturate(0%) hue-rotate(244deg) brightness(102%) contrast(85%)" }} />
                      <div className={styles.nome_card}> {this.props.t("labels.sensors")} </div>
                    </div>
                  </Paper>

                  <Paper zDepth={2} className={permissoes.pareto ? styles.option : styles.disable} onClick={permissoes.pareto ? () => this.openModalPareto() : () => { }}>
                    <div className={styles.card}>
                      <img
                        src={'./assets/pareto-icon.svg'}
                        width={48}
                        height={48}
                        title={this.props.t("labels.pareto")}
                        style={{
                          filter: "invert(42%) sepia(68%) saturate(0%) hue-rotate(244deg) brightness(102%) contrast(85%)",
                          transform: "rotate(90deg)"
                        }}
                      />
                      <div className={styles.nome_card}> {this.props.t("labels.pareto")} </div>
                    </div>
                  </Paper>
                </div>
              </div>
            </div>
          </div>
        </Container>
      );
    }
  }
}

export default withTranslation("reports")(MenuRelatorio)
