import React, { Component } from "react";
import Container from "../../common/Container";
import MDSpinner from "react-md-spinner";
import moment from "moment";
import Util from "../../utils/Utils";
import update from "immutability-helper";
import {
  requestRelatorioXLSX,
  request,
} from "../../common/request";
import {
  RaisedButton,
  Checkbox,
  Divider,
  AutoComplete,
  TextField,
} from "material-ui";
import {
  NForm,
  NFormTitle,
  NButtonGroup,
  alertBox,
  NInputTime,
  NDatePickerInput,
} from "../../common/NForm";
import { withTranslation } from "react-i18next";

var loteRequestTimeout = {};

class ModalRelatorioOeePorOrdem extends Container {
  constructor(props) {
    super(props);

    this.state = {
      unidadeIndustrialSelected: undefined,
      equipamentoSelected: undefined,
      loteSelected: undefined,

      // Checkboxes
      areaIndustrialChecked: false,
      unidadeIndustrialChecked: false,
      equipamentoChecked: false,
      skuChecked: false,
      loteChecked: false,
      rejeitadosChecked: false,
      ordensPredecessorasChecked: true,
      normaisInconsistentesChecked: true,
      referenciaSecundariaChecked: false,
      loteCodigoParadasChecked: false,
      separarColunaDataHoraChecked: false,

      // InputDisplayTexts
      unidadeIndustrialText: "",
      equipamentoText: "",
      loteText: "",
      periodoRelatorioText: this.props.t("date.calendar.today", { ns: 'common' }),

      // Errors
      errors: {
        dataInicioObrigatoria: undefined,
        dataFimObrigatoria: undefined,
      },
      erroSessaoUm: false,
      erroUnidadeIndustrial: false,
      erroEquipamento: false,
      erroSku: false,
      erroLote: false,

      // Datas e Validações de data
      dataInicio: undefined,
      dataFim: undefined,
      dataInicioText: "",
      dataFimText: "",
      horaInicio: undefined,
      horaFim: undefined,
      maxDate: moment(),

      // Outros
      linhaErro: "2px solid red",
      linhaNormal: "1px solid rgb(224, 224, 224)",
      opcoesEquipamentos: [],
      btnGerarDisabled: false,
      periodoOpcoes: [
        { label: this.props.t("date.calendar.today", { ns: 'common' }), value: this.props.t("date.calendar.today", { ns: 'common' }) },
        { label: this.props.t("date.calendar.yesterday", { ns: 'common' }), value: this.props.t("date.calendar.yesterday", { ns: 'common' }) },
        { label: this.props.t("date.calendar.currentWeek", { ns: 'common' }), value: this.props.t("date.calendar.currentWeek", { ns: 'common' }) },
        { label: this.props.t("date.calendar.currentMonth", { ns: 'common' }), value: this.props.t("date.calendar.currentMonth", { ns: 'common' }) },
        { label: this.props.t("labels.customize", { ns: 'common' }), value: this.props.t("labels.customize", { ns: 'common' }) },
      ],
      equipamentos: [],
      ehGrupoEquipamento: false,
      skus: []
    }

    this.checkUnidadeIndustrial = this.checkUnidadeIndustrial.bind(this);
    this.checkEquipamento = this.checkEquipamento.bind(this);
    this.checkSku = this.checkSku.bind(this);
    this.carregarTodosEquipamentos = this.carregarTodosEquipamentos.bind(this);
    this.carregarTodosSkus = this.carregarTodosSkus.bind(this);
    this.checkLote = this.checkLote.bind(this);
    this.setUnidadeIndustrial = this.setUnidadeIndustrial.bind(this);
    this.limpaUnidadeIndustrial = this.limpaUnidadeIndustrial.bind(this);
    this.handlePeriodoClick = this.handlePeriodoClick.bind(this);
    this.setLote = this.setLote.bind(this);
    this.gerarRelatorioOeePorOrdem = this.gerarRelatorioOeePorOrdem.bind(this);
    this.erroRelatorio = this.erroRelatorio.bind(this);
    this.requestLote = this.requestLote.bind(this);
    this.setEquipamento = this.setEquipamento.bind(this);
    this.setSku = this.setSku.bind(this);
    this.atualizarStatePorNome = this.atualizarStatePorNome.bind(this);
    this.validarDatas = this.validarDatas.bind(this);
    this.updateDataInicio = this.updateDataInicio.bind(this);
    this.updateDataFim = this.updateDataFim.bind(this);
    this.limparData = this.limparData.bind(this);
    this.carregarEquipamentos = this.carregarEquipamentos.bind(this);
  }

  // MANIPULAÇÃO DA UNIDADE INDUSTRIAL
  checkUnidadeIndustrial(value) {
    if (!value) {
      this.limpaUnidadeIndustrial();
      this.carregarTodosEquipamentos();
    }

    this.setState({
      unidadeIndustrialChecked: value,
      erroSessaoUm: false
    });
  }

  setUnidadeIndustrial(nomeUnidade, unidadesIndustriais) {
    let unidadeIndustrial = unidadesIndustriais.find(i => i.text === nomeUnidade);

    if (!unidadeIndustrial) {
      this.setState({
        unidadeIndustrialSelected: undefined,
        unidadeIndustrialText: "",
      });

      return;
    }

    const unidadeIndustrialStore = this.store.sessions.find(s => s.id === unidadeIndustrial.value);

    if (unidadeIndustrialStore) {
      this.carregarEquipamentos(unidadeIndustrialStore);
    }

    this.setState((_state, _props) => ({
      unidadeIndustrialSelected: unidadeIndustrial,
      unidadeIndustrialText: unidadeIndustrial.text,
      erroUnidadeIndustrial: false,
    }));
  }

  limpaUnidadeIndustrial() {
    this.setState({
      unidadeIndustrialSelected: undefined,
      unidadeIndustrialText: "",
      equipamentoSelected: undefined,
      equipamentoText: "",
      loteSelected: undefined,
      loteText: "",
    });
  }

  // MANIPULAÇÃO DO EQUIPAMENTO
  checkEquipamento(equipChecked) {
    this.setState({
      equipamentoChecked: equipChecked,
      erroSessaoUm: false
    });

    if (!equipChecked) {
      this.setState({
        equipamentoText: "",
        equipamentoSelected: undefined
      });

    } else {
      if (!this.state.unidadeIndustrialSelected) {
        this.carregarTodosEquipamentos();
      };
    }
  }

  checkSku(skuChecked) {
    this.setState({
      skuChecked: skuChecked,
      erroSessaoUm: false
    });

    if (!skuChecked) {
      this.setState({
        skuText: "",
        skuSelected: undefined
      });

    } else {
      if (!this.state.skuSelected) {
        this.carregarTodosSkus();
      };
    }
  }

  setEquipamento(nomeEquipamento, equipamentos) {
    let equipamentoSelecionado = equipamentos.find(i => i.text === nomeEquipamento);

    this.setState({
      equipamentoSelected: equipamentoSelecionado,
      equipamentoText: nomeEquipamento,
      erroEquipamento: false,
      ehGrupoEquipamento: equipamentoSelecionado?.ehGrupo ? true : false
    });
  }

  setSku(sku, skus) {
    let skuSelecionado = skus.find(i => i.text === sku);

    this.setState({
      skuSelected: skuSelecionado,
      skuText: sku,
      erroSku: false
    });
  }

  carregarTodosSkus() {
    if (this.state.skus.length > 0) {
      const opcoesSkus = this.state.skus.map(e => {
        return { text: `${e.sku} - ${e.nome}`, value: e.id };
      });

      this.setState({
        opcoesSkus: opcoesSkus
      });

      return;
    }

    request(this.endpoints.PRODUTO, { method: "GET" })
      .then(response => {
        if (response.ok)
          return response.json();
      })
      .then(skus => {
        const opcoesSkus = skus.map(e => {
          return { text: `${e.sku} - ${e.nome}`, value: e.id };
        });

        this.setState({
          skus: skus,
          opcoesEquipamentos: opcoesSkus
        });
      });
  }

  carregarTodosEquipamentos() {
    if (this.state.equipamentos.length > 0) {
      const opcoesEquipamentos = this.state.equipamentos.map(e => {
        return { text: `${e.tag} - ${e.nome}`, value: e.id };
      });

      this.setState({
        opcoesEquipamentos: opcoesEquipamentos,
        ehGrupoEquipamento: false
      });

      return;
    }

    request(this.endpoints.EQUIPAMENTOS, { method: "GET" })
      .then(response => {
        if (response.ok)
          return response.json();
      })
      .then(equipamentos => {
        const opcoesEquipamentos = equipamentos.map(e => {
          return { text: `${e.tag} - ${e.nome}`, value: e.id };
        });

        this.setState({
          equipamentos: equipamentos,
          opcoesEquipamentos: opcoesEquipamentos,
          ehGrupoEquipamento: false
        });
      });
  }

  /**
   * Carrega as opções de equipamento conforme os parâmetros informados.
   * 
   * @param {*} unidadeIndustrial 
   * @param {*} etapaSelected 
   */
  carregarEquipamentos(unidadeIndustrial, etapaSelected) {
    const opcoesEquipamentos = this.filtrarEquipamentosPorEtapa(unidadeIndustrial.stages, etapaSelected);

    this.setState({
      opcoesEquipamentos: opcoesEquipamentos,
      ehGrupoEquipamento: false
    });
  }

  filtrarEquipamentosPorEtapa(etapas, etapaSelected) {
    let equipamentos = [];
    let opcoesEquipamentos = [];

    // if (etapaSelected) {
    //   const etapa = etapas.find(item => item.id === etapaSelected.value);
    //   equipamentos.push(...etapa.equipments);
    // } else {
    //   for (const etapa of etapas) {
    //     equipamentos.push(...etapa.equipments);
    //   }
    // }

    for (const equipamento of equipamentos) {
      opcoesEquipamentos.push({
        text: `${equipamento.tag} - ${equipamento.name}`,
        value: equipamento.id
      });
    }

    return opcoesEquipamentos;
  }

  // MANIPULAÇÃO DO LOTE
  checkLote(_event, value) {
    this.setState({
      loteChecked: value,
      ordensPredecessorasChecked: true,
      erroSessaoUm: false
    })

    if (!value) {
      this.setState({
        loteSelected: undefined,
        loteText: ""
      });
    }
  }

  setLote(event) {
    clearTimeout(loteRequestTimeout);
    let numeroLote = event.target.value.trim();

    if (numeroLote) {
      this.setState((_state, _props) => ({ btnGerarDisabled: true, erroLote: false }));
      loteRequestTimeout = setTimeout(() => this.requestLote(numeroLote), 1000);
    }
  }

  requestLote(numeroLote) {
    request(this.endpoints.LOTE_NUMERO + `/${numeroLote}?finalizado=true`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      }).then(data => {
        if (!data) {
          this.setState({
            loteSelected: undefined,
            loteText: "",
            btnGerarDisabled: false,
          });

          return;
        }

        this.setState({
          loteText: data.numero,
          loteSelected: {
            id: data.id,
            numero: data.numero,
          },
          btnGerarDisabled: false,
        });
      });
  }

  handlePeriodoClick(_event, value) {
    this.setState({
      periodoRelatorioText: value
    });
  }

  gerarRelatorioOeePorOrdem() {
    let {
      unidadeIndustrialSelected,
      equipamentoSelected,
      skuSelected,
      loteSelected,
      periodoRelatorioText,
      dataInicio,
      horaInicio,
      dataFim,
      horaFim,
      normaisInconsistentesChecked,
      rejeitadosChecked,
      unidadeIndustrialChecked,
      equipamentoChecked,
      skuChecked,
      loteChecked,
      ehGrupoEquipamento
    } = this.state;

    var error = false;

    if (unidadeIndustrialChecked && !unidadeIndustrialSelected) {
      this.setState({ erroUnidadeIndustrial: true });
      error = true;
      alertBox("Informe uma unidade industrial", this.props.t("labels.warning", { ns: 'common' }), "warning");
    } else {
      this.setState({ erroUnidadeIndustrial: false });
    }

    if (equipamentoChecked && !equipamentoSelected) {
      this.setState({ erroEquipamento: true });
      alertBox("Informe um equipamento.", this.props.t("labels.warning", { ns: 'common' }), "warning");
      error = true;
    } else {
      this.setState({ erroEquipamento: false });
    }

    if (skuChecked && !skuSelected) {
      this.setState({ erroSku: true });
      alertBox("Informe um SKU.", this.props.t("labels.warning", { ns: 'common' }), "warning");
      error = true;
    } else {
      this.setState({ erroSku: false });
    }

    if (loteChecked && !loteSelected) {
      this.setState({ erroLote: true });
      alertBox(
        `Insira ${this.utils.getReferenciaUmLoteUmaOrdem()} existente.`,
        this.utils.getReferenciaLoteInvalidoOrdemInvalida(),
        "warning");
      error = true;
    } else {
      this.setState({ erroLote: false });
    }

    if ((!unidadeIndustrialChecked && !equipamentoChecked && !loteChecked && !skuChecked) ||
      (!normaisInconsistentesChecked && !rejeitadosChecked)) {
      alertBox(this.props.t("errors.noOptionsSelected"), this.props.t("labels.warning", { ns: 'common' }), "warning");
      error = true;
    }

    if (periodoRelatorioText === this.props.t("labels.custom")) {
      if (!dataInicio || !dataInicio.isValid()) {
        this.setState((_state, _props) => ({ dataInicio: undefined }));
        alertBox(this.props.t("errors.invalidStartDate"), this.props.t("labels.warning", { ns: 'common' }), "warning");
        error = true;
      }

      if (!dataFim || !dataFim.isValid()) {
        this.setState((_state, _props) => ({ dataFim: undefined }));
        alertBox(this.props.t("errors.invalidEndDate"), this.props.t("labels.warning", { ns: 'common' }), "warning");
        error = true;
      }

      if (!dataInicio || !dataFim) {
        this.setState({ erroDatas: true });
        error = true;
      }

      if (!dataInicio && dataFim) {
        this.setState({ erroDataInicial: true });
        error = true;
      }

      if (dataInicio && !dataFim) {
        this.setState({ erroDataFinal: true });
        error = true;
      }

      if (dataInicio && !dataFim) {
        if (moment(dataFim).format("YYYY-MM-DD").localeCompare(moment(dataInicio).format("YYYY-MM-DD")) === -1) {
          this.setState({
            erroDatas: false,
            erroDataFinal: true
          });

          error = true;
          alertBox(this.props.t("errors.endDateLessThanStartDate"), this.props.t("labels.warning", { ns: 'common' }), "warning");

        } else {
          this.setState({
            erroDatas: false,
            erroDataInicial: false,
            erroDataFinal: false
          });
        }
      }
    }

    if (!loteSelected) {
      if (!periodoRelatorioText) {
        this.setState({ erroPeriodo: true });
        error = true;
      } else {
        this.setState({ erroPeriodo: false });
      }
    }

    if (!unidadeIndustrialChecked && !equipamentoChecked && !loteChecked && !skuChecked) {
      this.setState({ erroSessaoUm: true });

      error = true;
    }

    if (!normaisInconsistentesChecked && !rejeitadosChecked) {
      this.setState({ erroInformacoes: true });
      error = true;

    } else {
      this.setState({ erroInformacoes: false });
    }

    let url = `${this.endpoints.RELATORIO_OEE_POR_ORDEM}`;

    if (periodoRelatorioText === this.props.t("labels.custom")) {
      if (dataInicio && dataFim) {
        dataInicio = moment(dataInicio);
        dataFim = moment(dataFim);
      }
    } else {
      dataFim = moment();

      switch (periodoRelatorioText) {
        case this.props.t("date.calendar.today", { ns: 'common' }):
          dataInicio = moment();
          break;

        case this.props.t("date.calendar.yesterday", { ns: 'common' }):
          dataInicio = dataFim = moment().subtract(1, "days");
          break;

        case this.props.t("date.calendar.currentWeek", { ns: 'common' }):
          dataInicio = moment().startOf("week");
          break;

        case this.props.t("date.calendar.currentMonth", { ns: 'common' }):
          dataInicio = moment().startOf("month");
          break;
      }
    }

    dataInicio = dataInicio.format("DD-MM-YYYY")
    dataFim = dataFim.format("DD-MM-YYYY")

    if (!horaInicio) {
      horaInicio = "00:00:00";
    }

    if (!horaFim) {
      horaFim = "23:59:59";
    }

    var postData = {
      unidadeIndustrialId: unidadeIndustrialSelected?.value,
      equipamentoId: equipamentoSelected?.value,
      grupoEquipamento: ehGrupoEquipamento,
      loteId: loteSelected?.id,
      produtoId: skuSelected?.value,
      dataInicio: dataInicio + " " + horaInicio,
      dataFim: dataFim + " " + horaFim
    }

    if (error === false) {
      this.props.openModal("loading_relatorio");

      requestRelatorioXLSX(url, this.props.t("titles.oeeByOrderReport", { primaryReference: this.utils.getReferenciaLoteOrdem() }), postData)
        .then(() => this.props.closeModal(),
          erro => this.erroRelatorio(erro))
        .catch(erro => this.erroRelatorio(erro));
    }
  }

  erroRelatorio(erro) {
    if (typeof erro === 'object') {
      alertBox(erro.mensagem, this.props.t("labels.warning", { ns: 'common' }), "warning");
      this.props.openModal("oee_by_order");
    } else if (Util.isJson(erro) && JSON.parse(erro).status === "erro") {
      alertBox(JSON.parse(erro).mensagem, this.props.t("labels.warning", { ns: 'common' }), "warning");
      this.props.openModal("oee_by_order");
    } else {
      this.setState({
        modal: true,
        tipoModal: "erro",
        erroMSG: this.props.t("errors.cannotGenerateReport")
      });
    }
  }

  validarDatas() {
    let dataInicio = this.state.dataInicio;
    let dataFim = this.state.dataFim;
    let hoje = moment();

    if (dataInicio && dataInicio.isAfter(hoje)) {
      this.alertDataMaiorQueAtual("dataInicio");

      return;
    }

    if (dataFim && dataFim.isAfter(hoje)) {
      this.alertDataMaiorQueAtual("dataFim");

      return;
    }

    if (!dataInicio || !dataFim) return;

    if (dataFim.isAfter(this.state.maxDate)) {
      alertBox(this.props.t("errors.periodLimited"), this.props.t("labels.warning", { ns: 'common' }), "warning");

      this.limparData("dataFim");
    }

    if (dataInicio.isAfter(dataFim)) {
      alertBox(this.props.t("errors.endDateLessThanStartDate"), this.props.t("labels.warning", { ns: 'common' }), "warning");

      this.limparData("dataFim");
    }
  }

  alertDataMaiorQueAtual(campoData) {
    alertBox(this.props.t("errors.selectDateBeforeCurrentDate"), this.props.t("labels.warning", { ns: 'common' }), "warning");
    this.limparData(campoData);
  }

  limparData(nomeCampo) {
    this.setState((_state, _props) => ({
      [nomeCampo]: undefined,
      [nomeCampo + "Text"]: "",
      [nomeCampo + this.props.t("labels.required")]: undefined,
    }));
  }

  ampersand(url) {
    return (url[url.length - 1] === "?") ? "" : "&";
  }

  async atualizarStatePorNome(property) {
    let { name, value } = property;

    this.setState({
      [name]: moment(value, "DD/MM/YYYY"),
      [name + "Text"]: value,
    });
  }

  updateDataInicio(date) {
    this.setState(update(this.state, {
      dataInicio: { $set: date },
      dataInicioText: { $set: moment(date, "DD/MM/YYYY") },
      dataFim: { $set: "" },
      maxDate: { $set: moment().isBefore(moment(date).add(3, "months")) ? moment() : moment(date).add(3, "months") },
      errors: { dataInicioObrigatoria: { $set: undefined } }
    }));
  }

  updateDataFim(date) {
    this.setState(update(this.state, {
      dataFim: { $set: date },
      dataFimText: { $set: moment(date, "DD/MM/YYYY") },
      errors: { dataFimObrigatoria: { $set: undefined } },
    }));
  }

  render() {
    let {
      erroUnidadeIndustrial,
      erroEquipamento,
      erroSku,
      erroLote,
      linhaErro,
      linhaNormal,
    } = this.state;

    let unidadesIndustriais = this.store.sessions.filter(s => s.stages.length);
    var opcoesUnidade = [];

    unidadesIndustriais.forEach(unidade => {
      opcoesUnidade.push({
        text: unidade.name,
        value: unidade.id
      });
    });

    return (
      <NForm style={{ width: 490 }}>
        <NFormTitle>
          {this.props.t("titles.oeeByOrderReport", { primaryReference: this.utils.getReferenciaLoteOrdem() })}
        </NFormTitle>

        <div className={"flex flex-dir-col"} style={{ margin: "10px 0 20px", position: "relative" }}>

          <div className={"flex flex-align-center"} style={{ minHeight: 35, boxSizing: "border-box" }}>
            <Checkbox
              label={this.props.t("labels.industrialUnit")}
              checked={this.state.unidadeIndustrialChecked}
              onCheck={(_event, value) => this.checkUnidadeIndustrial(value)}
              style={{ width: "40%" }}
              iconStyle={{ fill: this.state.erroSessaoUm ? "#F00" : "#2b4d7c", marginRight: 10 }}
              labelStyle={{ width: "100%" }}
            />

            {
              this.state.unidadeIndustrialChecked ?
                <AutoComplete
                  id="unidadeIndustrial"
                  fullWidth={true}
                  style={{ width: "60%" }}
                  inputStyle={{ marginTop: 0, height: 35 }}
                  underlineFocusStyle={{ borderColor: "#000000", bottom: 2 }}
                  underlineStyle={{ bottom: 2, borderBottom: (erroUnidadeIndustrial ? linhaErro : linhaNormal) }}
                  textFieldStyle={{ marginTop: -1, height: 35, width: "100%" }}
                  floatingLabelStyle={{ color: "#5B5B5B", textTransform: "uppercase", top: 0 }}
                  placeholder={this.props.t("labels.industrialUnit")}
                  title={this.props.t("labels.industrialUnit")}
                  onUpdateInput={this.setUnidadeIndustrial}
                  filter={AutoComplete.fuzzyFilter}
                  dataSource={opcoesUnidade}
                  onClick={() => {
                    this.limpaUnidadeIndustrial();
                    this.carregarTodosEquipamentos();
                  }}
                  openOnFocus={true}
                  popoverProps={{ className: "autocomplete" }}
                />
                : null
            }
          </div>

          <div className={"flex flex-align-center"} style={{ minHeight: 35, boxSizing: "border-box" }}>
            <Checkbox
              label={this.props.t("labels.sku").toUpperCase()}
              checked={this.state.skuChecked}
              onCheck={(_event, value) => this.checkSku(value)}
              style={{ width: "40%" }}
              iconStyle={{ fill: this.state.erroSessaoUm ? "#F00" : "#2b4d7c", marginRight: 10 }}
            />
            {
              this.state.skuChecked
                ?
                <AutoComplete
                  onUpdateInput={this.setSku}
                  id={"sku"}
                  fullWidth={true}
                  style={{ width: "60%" }}
                  inputStyle={{ marginTop: 0, height: 35 }}
                  underlineFocusStyle={{ borderColor: "#000000", bottom: 2 }}
                  underlineStyle={{ bottom: 2, borderBottom: (erroSku ? linhaErro : linhaNormal) }}
                  textFieldStyle={{ marginTop: -1, height: 35, width: "100%" }}
                  floatingLabelStyle={{ color: "#5B5B5B", textTransform: "uppercase", top: 0 }}
                  filter={AutoComplete.fuzzyFilter}
                  placeholder={this.props.t("labels.sku").toUpperCase()}
                  title={this.props.t("labels.sku").toUpperCase()}
                  dataSource={this.props.listSkus}
                  openOnFocus={true}
                  popoverProps={{ className: "autocomplete" }}
                  onClick={() => this.setState({
                    skuText: "",
                    skuSelected: undefined
                  })}
                />
                : null
            }
          </div>

          <div className={"flex flex-align-center"} style={{ minHeight: 35, boxSizing: "border-box" }}>
            <Checkbox
              label={this.props.t("labels.equipment")}
              checked={this.state.equipamentoChecked}
              onCheck={(_event, value) => this.checkEquipamento(value)}
              style={{ width: "40%" }}
              iconStyle={{ fill: this.state.erroSessaoUm ? "#F00" : "#2b4d7c", marginRight: 10 }}
            />
            {
              this.state.equipamentoChecked
                ?
                <AutoComplete
                  onUpdateInput={this.setEquipamento}
                  id={"equipamento"}
                  fullWidth={true}
                  style={{ width: "60%" }}
                  inputStyle={{ marginTop: 0, height: 35 }}
                  underlineFocusStyle={{ borderColor: "#000000", bottom: 2 }}
                  underlineStyle={{ bottom: 2, borderBottom: (erroEquipamento ? linhaErro : linhaNormal) }}
                  textFieldStyle={{ marginTop: -1, height: 35, width: "100%" }}
                  floatingLabelStyle={{ color: "#5B5B5B", textTransform: "uppercase", top: 0 }}
                  filter={AutoComplete.fuzzyFilter}
                  placeholder={this.props.t("labels.equipment")}
                  title={this.props.t("labels.equipment")}
                  dataSource={this.props.listEquipments}
                  openOnFocus={true}
                  popoverProps={{ className: "autocomplete" }}
                  onClick={() => this.setState({
                    equipamentoText: "",
                    equipamentoSelected: undefined
                  })}
                />
                : null
            }
          </div>

          <div className={"flex flex-align-center"} style={{ minHeight: 35, boxSizing: "border-box" }}>
            <Checkbox
              label={this.utils.getReferenciaLoteOrdem()}
              checked={this.state.loteChecked}
              onCheck={this.checkLote}
              style={{ width: "40%" }}
              iconStyle={{ fill: this.state.erroSessaoUm ? "#F00" : "#2b4d7c", marginRight: 10 }}
            />

            {
              this.state.loteChecked
                ?
                <span style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row-reverse",
                }}>
                  <TextField
                    id={"lote"}
                    fullWidth={true}
                    inputStyle={{ height: 32 }}
                    style={{ width: "84%", height: 34 }}
                    underlineStyle={{ bottom: 2, borderBottom: (erroLote ? linhaErro : linhaNormal), width: "100%" }}
                    underlineFocusStyle={{ bottom: 2, borderColor: "#000000" }}
                    onKeyUp={this.setLote}
                    floatingLabelStyle={{ color: "#5B5B5B", textTransform: "uppercase" }}
                    placeholder={this.utils.getReferenciaLoteOrdem()}
                  />
                  {
                    this.state.btnGerarDisabled
                      ? <MDSpinner singleColor="#2D4F7F" size={20} style={{ marginTop: 4, marginRight: 6 }} />
                      : null
                  }
                </span>
                : null
            }
          </div>
        </div>

        {this.state.loteChecked
          ? null
          : <div>
            <Divider />

            <h3 style={{ paddingTop: "1.5em", paddingBottom: ".5em", fontSize: "0.8em", textTransform: 'uppercase' }}>
              {this.props.t("labels.selectPeriod")}
            </h3>

            <div className="flex flex-dir-row flex-align-end" style={{ marginBottom: 20, paddingTop: 10 }}>
              <div className="flex flex-dir-col flex-align-start" style={{ width: "30%", justifyContent: "center" }}>
                {
                  this.state.periodoOpcoes.map((radio, index) => {
                    return <RadioPeriodo
                      key={index}
                      periodoRelatorioText={this.state.periodoRelatorioText}
                      onClick={this.handlePeriodoClick}
                      label={radio.label}
                      value={radio.value}
                    />
                  })
                }
              </div>


            </div>

            {
              this.state.periodoRelatorioText == this.props.t("labels.custom") ?
                <div className={'flex'} style={{ marginTop: '-1em', marginBottom: '1em' }}>
                  <div style={{ margin: '0 0.8em', width: '27%' }}>
                    <NDatePickerInput
                      require={this.state.erroDataInicioReport}
                      id="start_date"
                      label={this.props.t("labels.startDate")}
                      value={this.state.dataInicioText}
                      onSelect={date => this.setState({ dataInicioText: date, dataInicio: date })}
                      onChangeRaw={(_, date) => this.setState({ dataInicioText: date, dataInicio: date })}
                      onChange={date => this.setState({ dataInicioText: date, dataInicio: date })}
                      required={this.state.errors.dataInicioObrigatoria}
                      style={{ fontSize: '.96rem' }}
                      autoComplete="off" />
                  </div>

                  <NInputTime
                    step={1} // hora com minutos e segundos
                    type='time'
                    id='horaInicial'
                    label={this.props.t("labels.startHour")}
                    style={{ width: '25%', marginRight: '2.0%', fontSize: '.96rem' }}
                    value={this.state.horaInicio}
                    onChange={(event, value) => this.setState({ horaInicio: value })}
                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '1rem', background: '#fff', textTransform: 'uppercase' }}
                    errorText={this.state.erroHoraInicioReport} />

                  <div style={{ margin: '0 0.8em', width: '27%' }}>
                    <NDatePickerInput
                      require={this.state.erroDataFimReport}
                      id="end_date"
                      value={this.state.dataFimText}
                      minDate={this.state.dataInicio}
                      maxDate={this.state.maxDate}
                      label={this.props.t("labels.endDate")}
                      onSelect={date => this.setState({ dataFimText: date, dataFim: date })}
                      onChangeRaw={(_, date) => this.setState({ dataFimText: date, dataFim: date })}
                      onChange={date => this.setState({ dataFimText: date, dataFim: date })}
                      required={this.state.errors.dataFimObrigatoria}
                      style={{ fontSize: '.96rem' }}
                      autoComplete="off" />
                  </div>

                  <NInputTime
                    step={1} // hora com minutos e segundos
                    type='time'
                    id='horaFim'
                    label={this.props.t("labels.endHour")}
                    style={{ width: '25%', marginRight: '1.0%', fontSize: '.96rem' }}
                    value={this.state.horaFim}
                    onChange={(event, value) => this.setState({ horaFim: value })}
                    floatingLabelStyle={{ color: '#5B5B5B', fontSize: '1rem', background: '#fff', textTransform: 'uppercase' }}
                    errorText={this.state.erroHoraFimReport} />
                </div>
                : null
            }
          </div>}

        <NButtonGroup>
          <RaisedButton
            onClick={this.gerarRelatorioOeePorOrdem}
            backgroundColor="#2D4F7F"
            buttonStyle={{ fontFamily: "" }}
            style={{ fontWeigth: 100 }}
            labelColor="#FFF"
            label={this.props.t("labels.generate", { ns: 'common' })}
            disabled={this.state.btnGerarDisabled}
          />

          <RaisedButton
            onClick={this.props.closeModal}
            buttonStyle={{ fontFamily: "" }}
            style={{
              fontWeigth: 100,
              marginLeft: 16
            }}
            label={this.props.t("labels.cancel", { ns: 'common' })} />
        </NButtonGroup>
      </NForm>
    );
  }
}

class RadioPeriodo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      periodoRelatorioText,
      onClick,
      label,
      value
    } = this.props;

    return (
      <div className={"flex flex-dir-row flex-just-between flex-align-center"}>
        <label
          className="control control-radio"
          htmlFor={`radioSete-${value}`}
          style={{
            height: "1.2em",
            width: 130,
            textAlign: "left",
            paddingTop: 9
          }}
          onClick={event => onClick(event, value)}>
          <span style={{ marginLeft: "2em", width: 300 }}>
            {label}
          </span>

          <input type="radio" name="periodo" checked={periodoRelatorioText === value} id={`radioSete-${value}`} />
          <div
            style={{ top: ".2em" }}
            className="control_indicator"
            data-value={value}>
          </div>
        </label>
      </div>
    )
  }
}

export default withTranslation("reports")(ModalRelatorioOeePorOrdem);